function MenuIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 19 14"
            width="19px"
            {...props}
        >
            <path
                d="M19 12.296V14H0v-1.704h19zm0-6.148v1.704H0V6.148h19zM19 0v1.704H0V0h19z"
                fillRule="evenodd"
                fill={`${props.fill || '#fff'}`}
                {...props}
            />
        </svg>
    );
}

export default MenuIcon;
