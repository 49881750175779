import styled from 'styled-components';
import Link from 'next/link';

import { useTranslation } from '@hooks';
import { TickIcon } from '@components/icons';

import { Caption } from '@components/styles/Typography';

const SliderVariant = ({
    variantColors,
    onProductChange,
    selectedVariant,
    slug,
}) => {
    const { t } = useTranslation();
    return (
        <ColorVariantsContainer>
            {variantColors?.slice(0, 4).map((variantColor) => (
                <ColorVariantsWrapper
                    key={variantColor?.id}
                    isWhite={
                        variantColor.hex_code === '#f5f5f5' ||
                        variantColor.hex_code === '#ffffff'
                    }
                    isSelected={selectedVariant === variantColor?.id}
                >
                    <ColorVariantItem
                        alt={variantColor?.id}
                        src={variantColor?.image_colour}
                        loading="lazy"
                        onClick={() => onProductChange(variantColor?.id)}
                    />
                    {selectedVariant === variantColor.id && <TickIcon />}
                </ColorVariantsWrapper>
            ))}
            {variantColors?.length > 4 && (
                <Link href={`/${t('productSlug')}/${slug}`} passHref>
                    <ColorVariantCount>
                        +{variantColors.length - 4}
                    </ColorVariantCount>
                </Link>
            )}
        </ColorVariantsContainer>
    );
};
const ColorVariantsContainer = styled.div`
    display: flex;
    padding: 0 6px;
`;
const ColorVariantsWrapper = styled.div`
    margin-right: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
        position: absolute;
        width: 8px;
        path {
            fill: ${({ isWhite, theme }) =>
                isWhite && `${theme.colors.primary}`};
        }
    }
    :last-child {
        margin-right: 0;
    }
    ::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 15px;
        height: 15px;
        border-radius: 50%;
        box-shadow: 0px 0px 0px 0.6px rgba(0, 0, 0, 0.07);
        z-index: -1;
    }
`;
const ColorVariantItem = styled.img`
    object-fit: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: ${({ theme }) => `1px solid ${theme.colors.bgGray10}`};
`;
const ColorVariantCount = styled(Caption)`
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default SliderVariant;
