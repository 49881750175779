import React from 'react';
import styled from 'styled-components';

const ButtonNew = ({
    content,
    type,
    size,
    uppercase,
    handleClick,
    children,
    ...rest
}) => (
    <NewButton
        type={type}
        size={size}
        onClick={handleClick}
        uppercase={uppercase}
        {...rest}
    >
        {content || children}
    </NewButton>
);

const NewButton = styled.button`
    font-family: var(--futura-font);
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    background: ${({ theme, type }) => theme.buttonTypes[type].background};
    font-size: ${({ theme, size }) => theme.buttonSizes[size].fontSize};
    width: ${({ theme, size }) => theme.buttonSizes[size].width};
    height: ${({ theme, size }) => theme.buttonSizes[size].height};
    color: ${({ theme, type }) => theme.buttonTypes[type].color};
    border: ${({ theme, type }) => theme.buttonTypes[type].border};
    padding: ${({ theme, size }) => theme.buttonSizes[size].padding};
    letter-spacing: ${({ theme, size }) =>
        theme.buttonSizes[size].letterSpacing};
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    :hover {
        background: ${({ theme, type }) =>
            theme.buttonTypes[type].hover.background};
        color: ${({ theme, type }) => theme.buttonTypes[type].hover.color};
        border: ${({ theme, type }) => theme.buttonTypes[type].hover.border};
    }
    :disabled {
        background: ${({ theme, type }) =>
            theme.buttonTypes[type].disabled.background};
        color: ${({ theme, type }) => theme.buttonTypes[type].disabled.color};
        border: ${({ theme, type }) => theme.buttonTypes[type].disabled.border};
        cursor: not-allowed;
    }

    @media (max-width: 1024px) {
        width: ${({ theme, size }) =>
            size === 'xlarge' && theme.buttonSizes[size].mobileWidth};
    }
`;

export default ButtonNew;
