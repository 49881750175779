function ProfileIcon(props) {
    return (
        <svg
            width={18}
            height={18}
            viewBox="0 0 22 22"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11 1c2.757 0 5 2.467 5 5.5 0 2.052-1.027 3.846-2.546 4.79 4.32 1.042 7.53 4.77 7.545 9.203l.001.03a.489.489 0 0 1-.5.477h-19a.489.489 0 0 1-.5-.476c0-4.446 3.214-8.19 7.545-9.234C7.027 10.345 6 8.552 6 6.5 6 3.467 8.243 1 11 1zm0 1C8.794 2 7 4.019 7 6.5c0 1.885 1.036 3.503 2.5 4.172v1.445C5.402 12.765 2.233 16.021 2 20h18c-.233-3.979-3.401-7.234-7.499-7.883v-1.446C13.965 10.002 15 8.384 15 6.5 15 4.019 13.206 2 11 2z"
                fill={`${props.fill || '#2E2E2E'}`}
                stroke={`${props.fill || '#2e2e2e'}`}
                strokeWidth={0.1}
            />
        </svg>
    );
}

export default ProfileIcon;
