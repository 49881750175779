import { getCookie } from 'cookies-next';

const fetcher = async (url, opt) => {
    const fbp = getCookie('_fbp');
    const options = { ...opt };
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        fbID: fbp,
    };
    options.headers = { ...options.headers, ...headers };
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}${url}`,
        options,
    );
    const response = await res.json();
    return response;
};

export default fetcher;
