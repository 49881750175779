import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { useWindowScroll } from 'react-use';

import { Logo, HeaderHorizontalMenu, HeaderMenu } from '@components/common';
import ChildHeaderElem from './ChildHeaderElem';

const Header = () => {
    const { y } = useWindowScroll();
    const router = useRouter();

    const isScrolling = y > 40;
    const [activeItem, setActiveItem] = useState(null);
    const [activeParent, setActiveParent] = useState(null);
    const headerRef = useRef();
    useEffect(() => {
        if (router.pathname !== '/[category]') setActiveParent(null);
    }, [router.pathname]);
    const homePage = router.pathname === '/';

    return (
        <Wrapper
            onMouseLeave={() => setActiveItem(null)}
            isScrolling={homePage ? false : isScrolling}
        >
            <Top isScrolling={isScrolling} ref={headerRef}>
                <Logo />
                <HeaderHorizontalMenu
                    setActiveItem={setActiveItem}
                    setActiveParent={setActiveParent}
                    activeParent={activeParent}
                />
                <HeaderMenu isScrolling={isScrolling} />
            </Top>
            <Bottom>
                <DummyLogo />
                <MenuItemContainer>
                    <MenuItems>
                        {activeItem?.child?.slice(1)?.map((child, c) => (
                            <ChildHeaderElem
                                key={`child-category-${c.toString()}`}
                                setActiveParent={() =>
                                    setActiveParent(activeItem?.id)
                                }
                                child={child}
                            />
                        ))}
                    </MenuItems>

                    <MenuAlignment>
                        <HeaderHorizontalMenu setActiveItem={setActiveItem} />
                    </MenuAlignment>
                </MenuItemContainer>
                <DummyMenu />
            </Bottom>
        </Wrapper>
    );
};

export default Header;

const Wrapper = styled.header`
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 30px;
    z-index: 99;
    background-color: ${({ isScrolling, theme }) =>
        isScrolling ? `${theme.colors.secondary}` : 'transparent'};
    transition: all 0.4s ease-in-out;
    transform: scaleY(1);
    padding: 20px 45px;

    ::before {
        content: '';
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: ${({ theme }) => theme.colors.secondary};
        transition: all 0.4s ease-in-out;
    }

    :hover {
        ::before {
            top: 0;
        }
    }
    transform-origin: top;
    @media (max-width: 768px) {
        display: none;
    }
    @media (max-width: 1024px) {
        display: none;
    }
`;

const Top = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Bottom = styled.div`
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
`;

const MenuItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 0 100px;
    p {
        justify-content: flex-start;
    }
`;
const MenuItems = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    gap: 100px;
    p {
        justify-content: flex-start;
    }
`;

const DummyLogo = styled.div`
    width: 100px;
`;
const DummyMenu = styled.div`
    width: 240px;
`;

const MenuAlignment = styled.div`
    opacity: 0;
    position: relative;
    z-index: -1;
    height: 0;
    @media (max-width: 1024px) {
        display: none;
    }
`;
