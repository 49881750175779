import styled from 'styled-components';
import { CloseIcon } from 'components/icons';

const NewCloseButton = ({ flexStart, close, right, desktopWhite, testId }) => (
    <CloseButtonWrapper
        flexStart={flexStart}
        onClick={close}
        right={right}
        desktopWhite={desktopWhite}
        data-testid={testId}
        aria-label="Close button"
    >
        <CloseIcon width="12px" height="12px" />
    </CloseButtonWrapper>
);

const CloseButtonWrapper = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    align-self: ${({ right }) => right && 'flex-end'};
`;

export default NewCloseButton;
