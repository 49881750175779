function FilledSearchIcon(props) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0V0z" />
                <path
                    d="M9.74 16.517a6.68 6.68 0 0 0 4.373-1.621l5.926 5.941a.56.56 0 0 0 .796.001.561.561 0 0 0 0-.795l-5.93-5.946c2.22-2.652 2.09-6.623-.397-9.117A6.69 6.69 0 0 0 9.739 3a6.69 6.69 0 0 0-4.768 1.98c-2.628 2.635-2.628 6.922 0 9.557a6.694 6.694 0 0 0 4.769 1.98zM5.768 5.775a5.572 5.572 0 0 1 3.972-1.65c1.5 0 2.91.586 3.972 1.65a5.65 5.65 0 0 1 0 7.968 5.572 5.572 0 0 1-3.972 1.65 5.58 5.58 0 0 1-3.972-1.65 5.652 5.652 0 0 1 0-7.968z"
                    stroke={`${props.fill || '#2E2E2E'}`}
                    fill={`${props.fill || '#2E2E2E'}`}
                    fillRule="nonzero"
                />
            </g>
        </svg>
    );
}

export default FilledSearchIcon;
