function NotificationCloseIcon(props) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12.0003 12.0003C11.9431 12.0563 11.8661 12.0875 11.786 12.0869C11.7089 12.0858 11.6353 12.0547 11.5807 12.0003L8.00038 8.41993L4.42004 12.0003C4.36548 12.0547 4.29187 12.0858 4.2148 12.0869C4.13461 12.0881 4.05736 12.0568 4.00065 12.0001C3.94394 11.9433 3.9126 11.8661 3.91378 11.7859C3.91491 11.7088 3.94599 11.6352 4.00043 11.5807L7.58077 8.00033L4.00043 4.41999C3.94591 4.36322 3.91354 4.28878 3.90922 4.21019C3.91371 4.04584 4.04589 3.91366 4.21024 3.90916C4.28883 3.91349 4.36327 3.94585 4.42004 4.00038L8.00038 7.58072L11.5807 4.00038C11.6353 3.94593 11.7089 3.91485 11.786 3.91372C11.8661 3.91255 11.9434 3.94389 12.0001 4.0006C12.0568 4.05731 12.0882 4.13456 12.087 4.21475C12.0859 4.29182 12.0548 4.36543 12.0003 4.41999L8.41998 8.00033L12.0003 11.5807C12.0548 11.6352 12.0859 11.7088 12.087 11.7859C12.0875 11.866 12.0564 11.9431 12.0003 12.0003Z"
                fill="black"
                stroke="black"
                stroke-width="0.1"
            />
        </svg>
    );
}

export default NotificationCloseIcon;
