function CloseIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
            width="11px"
            {...props}
        >
            <path
                d="M16.358 17.438l-7.064-7.064-7.064 7.064a.638.638 0 01-.904.001l-.177-.177a.637.637 0 010-.904l7.064-7.065L1.149 2.23a.637.637 0 010-.903l.177-.177a.641.641 0 01.904 0l7.064 7.063 7.064-7.064c.462-.186.472-.186.482-.185l.022.013c.617.373.705.568.737.734a.564.564 0 01-.16.52l-7.064 7.063 7.064 7.064a.639.639 0 010 .906l-.177.176a.637.637 0 01-.904 0z"
                stroke={`${props.fill || '#000'}`}
                strokeWidth={0.2}
            />
        </svg>
    );
}

export default CloseIcon;
