import { useQuery } from '@tanstack/react-query';
import fetcher from '@utils/fetcher';
import { useUserIP } from '@hooks';

export const useDeliveryDate = (id, forCart) => {
    const [ip] = useUserIP();
    const reqURL = forCart
        ? `v1/meta/delivery-date?ip-address=${ip}&product-ids=${id}`
        : `v1/meta/delivery-date?ip-address=${ip}&model-ids=${id}`;
    return useQuery({
        queryKey: ['getDeliveryDate', ip, id],
        queryFn: () => fetcher(reqURL),
        enabled: !!ip && !!id,
    });
};

const SharedServices = {
    getTrendOfWeeks: async () => fetcher('v1/product/weekly-featured-products'),
    getMenuItems: async () => fetcher('v1/menu'),
};

export default SharedServices;
