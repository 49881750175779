export const colors = {
    primary: '#000000',
    primaryVariant: '#333333',
    secondary: '#FFFFFF',
    secondaryVariant: '#E4E4E4',
    tertiaryVariant: '#F4F4F4',
    bgGray50: '#7F7F7F',
    bgGray25: '#BEBEBE',
    bgGray10: '#E4E4E4',
    buttonDisabled: '#CACACA',
    bgLight: '#FFFFFF',
    success: '#44B262',
    success50: '#ECF7EF',
    error: '#ED594C',
    error10: '#FDEEED',
    info50: '#EBF1FF',
    warning50: '#FCF4DE',
};

export const sizes = {
    xs: '10px',
    s: '12px',
    m: '14px',
    base: '16px',
    l: '18px',
    xl: '24px',
    xxl: '32px',
    '3xl': '50px',
    '4xl': '80px',
};

export const zIndexs = {
    accountModal: '999',
    searchModal: '998',
};
