import { useRouter } from 'next/router';
import styled from 'styled-components';
import { useWindowSize } from 'react-use';

import useTranslation from '@hooks/useTranslation';
import {
    RightModal,
    CloseButton,
    BottomModal,
    NewButton,
} from '@components/ui';
import { H1, Text, Subtitle2 } from '@components/styles/Typography';

const CampaignContent = ({ alreadySignedIn, closeModal }) => {
    const router = useRouter();
    const { t } = useTranslation();
    return (
        <ContentWrapper data-testid="campaign-container">
            <Subtitle2>{t('notSignupYet')}</Subtitle2>
            <Text>{t('signupNowCampaign')}</Text>
            <H1>
                % <bold>10</bold>{' '}
            </H1>
            <Text>{t('getDiscount')}</Text>
            {alreadySignedIn ? (
                <NewButton
                    type="primary"
                    size="xlarge"
                    content={t('continueShopping')}
                    handleClick={() => {
                        closeModal();
                        router.push(t('allProductSlug'));
                    }}
                />
            ) : (
                <NewButton
                    type="primary"
                    size="xlarge"
                    content={t('signup')}
                    handleClick={() => {
                        closeModal();
                        router.push('/login?tab=register');
                    }}
                />
            )}
        </ContentWrapper>
    );
};

const Campaign = ({ isOpen, closeModal, alreadySignedIn }) => {
    const { width } = useWindowSize();

    if (width < 768) {
        return (
            <BottomModal isOpen={isOpen} closeModal={closeModal}>
                <CloseButton right={5} close={closeModal} />

                <CampaignContent
                    alreadySignedIn={alreadySignedIn}
                    closeModal={closeModal}
                />
                <img
                    src="https://s3.eu-central-1.amazonaws.com/files.keikei.co/cdn/svgs/mobil.svg?a"
                    alt="keikei secondary logo"
                />
            </BottomModal>
        );
    }

    return (
        <RightModal isOpen={isOpen} closeModal={closeModal}>
            <CloseButton right={20} close={closeModal} />
            <CampaignContent
                alreadySignedIn={alreadySignedIn}
                closeModal={closeModal}
            />
            <img
                src="https://s3.eu-central-1.amazonaws.com/files.keikei.co/cdn/svgs/mobil.svg?a"
                alt="keikei secondary logo"
            />
        </RightModal>
    );
};

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    height: 100%;

    h1,
    p,
    span {
        margin: 5px auto;
    }

    h1 {
        width: 100%;
        text-align: center;
        bold {
            font-size: 80px;
            line-height: 100px;
            letter-spacing: 3px;
        }
    }
    button {
        margin-top: 20px;
    }
`;
export default Campaign;
