import styled from 'styled-components';

import { useCart } from '@context/CartContext';
import { BagIcon } from '@components/icons';
import FilledBagIcon from '@components/icons/FilledBagIcon';
import { Caption2 } from '@components/styles/Typography';

const CartButton = ({
    isScrolling,
    homePageHeader,
    onCartButtonClick,
    isSelectedMenu,
}) => {
    const { cartProductCount } = useCart();
    return (
        <Container
            onClick={onCartButtonClick}
            isWhite={!isScrolling && homePageHeader}
        >
            {isSelectedMenu ? <FilledBagIcon /> : <BagIcon fill="black" />}
            {cartProductCount > 0 && (
                <CountBadge isWhite={!isScrolling && homePageHeader}>
                    {cartProductCount}
                </CountBadge>
            )}
        </Container>
    );
};
const Container = styled.span`
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    span {
        display: inline-block;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 16px;
        height: 16px;
        padding: 4px;
        font-weight: 500;
        background: ${({ theme }) => theme.colors.primary};
        border-radius: 50%;
        position: absolute;
        right: -12px;
        top: -10px;
        color: ${({ theme }) => theme.colors.secondary} !important;
        @supports (-webkit-touch-callout: none) {
            right: -12px;
            top: -10px;
        }
    }
`;
const CountBadge = styled(Caption2)`
    color: ${({ isWhite, theme }) =>
        isWhite ? `${theme.colors.secondary}` : ``};
`;
export default CartButton;
