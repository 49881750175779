import styled from 'styled-components';
import Link from 'next/link';

import { useAuth } from 'context/AuthContext';
import { useModal } from 'context/ModalContext';
import { CartModal } from 'components/cart';
import { AccountModal } from '@components/account';
import { FavoriteIcon, ProfileIcon, SearchIcon } from 'components/icons';

import CartButton from '@components/cart/CartButton';
import { useAccount } from '@context/AccountContext';
import { truncateString } from '@utils';
import { Subtitle3 } from 'components/styles/Typography';

const HeaderMenu = ({ isScrolling, homePageHeader }) => {
    const { currentUser } = useAuth();
    const {
        openCartModal,
        openSearchModal,
        cartModalIsOpen,
        closeCartModal,
        rightModalIsOpen,
        openRightModal,
        closeRightModal,
    } = useModal();
    const { userData } = useAccount();

    return (
        <HeaderMenuWrapper>
            <HeaderMenuItem
                aria-label="account-button"
                onClick={openRightModal}
            >
                {currentUser && userData?.data?.data?.first_name ? (
                    <Subtitle3 onClick={openRightModal}>
                        {truncateString(
                            userData.data.data.first_name
                                .charAt(0)
                                .toUpperCase() +
                                userData.data.data.first_name.slice(1),
                            6,
                        )}
                    </Subtitle3>
                ) : (
                    <ProfileIcon fill="#000000" />
                )}
            </HeaderMenuItem>
            <HeaderMenuItem
                aria-label="search-button"
                onClick={openSearchModal}
            >
                <SearchIcon fill="black" />
            </HeaderMenuItem>
            <HeaderMenuItem aria-label="favorites-button">
                <Link
                    aria-label="favorites-button"
                    href="/account/favorites"
                    passHref
                >
                    <FavoriteIcon fill="black" />
                </Link>
            </HeaderMenuItem>
            <HeaderMenuItem aria-label="cart-button">
                <CartButton
                    isScrolling={isScrolling}
                    homePageHeader={homePageHeader}
                    onCartButtonClick={openCartModal}
                />
                <CartModal
                    currentUser={currentUser}
                    isOpen={cartModalIsOpen}
                    closeModal={closeCartModal}
                />
            </HeaderMenuItem>
            <AccountModal
                currentUser={currentUser}
                isOpen={rightModalIsOpen}
                closeModal={closeRightModal}
            />
        </HeaderMenuWrapper>
    );
};
const HeaderMenuWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 998;
`;
const HeaderMenuItem = styled.button`
    margin-left: 40px;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    :first-child {
        margin-left: 0;
    }
`;
export default HeaderMenu;
