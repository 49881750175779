import fetcher from '@utils/fetcher';

const AccountServices = {
    getUserData: (token) => {
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        return fetcher('v1/me', options);
    },
    getFavoritesModelIDs: (token) => {
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        return fetcher('v1/user/favorite-ids', options);
    },
    getReturnReasons: (token) => {
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        return fetcher('v1/sale/return-reasons', options);
    },
    getSale: (token, id) => {
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        return fetcher(`v1/sale/${id}`, options);
    },
    easyReturn: ({ token, saleID, items }) => {
        const options = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                items,
            }),
        };
        return fetcher(`v1/sale/return-request/${saleID}`, options);
    },
    getContactInfo: (token) => {
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        return fetcher(`v1/profile-address`, options);
    },
    getFavorites: (token) => {
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        return fetcher(`v1/user/favorites`, options);
    },
    getOrders: (token) => {
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        return fetcher(`v1/sale`, options);
    },
    getAddresses: (token) => {
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        return fetcher('v1/address', options);
    },
    addToFavorites: ({ modelID, token }) => {
        const options = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                model_id: modelID,
            }),
        };
        return fetcher('v1/favorite-add', options);
    },
    changePersonalInfo: ({
        token,
        name,
        surname,
        userBirthday,
        gender,
        ip,
        userID,
    }) => {
        const options = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                first_name: name,
                last_name: surname,
                birthday: userBirthday,
                ip_address: ip,
                gender,
                port: 80,
            }),
        };
        return fetcher(`v1/user/${userID}`, options);
    },
    submitComplaint: ({

        name,
        surname,
        email,
        message,
    }) => {
        const options = {
            method: 'POST',

            body: JSON.stringify({
                first_name: name,
                last_name: surname,
                email,
                message,
            }),
        };
        return fetcher(`v1/complaint`, options);
    },
    changeContactInfo: ({ data, location, ip, token }) => {
        const options = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                phone: {
                    country_code: data.countryCode,
                    area_code: data.phoneNumber.slice(0, 3),
                    phone: data.phoneNumber.slice(3, data.phoneNumber.length),
                },
                full_address: data.address,
                country_id: location.country_id,
                city_id: location.county_id,
                state_id: location.city_id,
                user_agreement: data.allowAgreement,
                ip_address: ip,
                port: 80,
            }),
        };
        return fetcher(`v1/profile-address`, options);
    },
    deleteFromFavorites: ({ modelID, token }) => {
        const options = {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        return fetcher(`v1/favorite-remove/${modelID}`, options);
    },

    deleteAddress: ({ addressID, token }) => {
        const options = {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        return fetcher(`v1/address/${addressID}`, options);
    },
    addNewAddress: ({ data, token, userID }) => {
        const options = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                user_id: userID,
                type: 'bireysel',
                first_name: data.name,
                last_name: data.surname,
                email: data.email,
                phone: {
                    country_code: data.countryCode,
                    area_code: data.phoneNumber.slice(0, 3),
                    phone: data.phoneNumber.slice(3, data.phoneNumber.length),
                },
                alias: data.addressTitle,
                full_address: data.address,
                zip_code: data.zipCode,
                state_id: data.cityID,
                country_id: data.countryID,
                ...(data.countyID && { city_id: data.countyID }),
            }),
        };
        return fetcher('v1/address', options);
    },
    changeAddress: ({ data, token, addressID, userID }) => {
        const options = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                user_id: userID,
                type: 'bireysel',
                first_name: data.name,
                last_name: data.surname,
                email: data.email,
                zip_code: data.zipCode,
                phone: {
                    country_code: data.countryCode,
                    area_code: data.phoneNumber.slice(0, 3),
                    phone: data.phoneNumber.slice(3, data.phoneNumber.length),
                },
                alias: data.addressTitle,
                full_address: data.address,
                country_id: data.countryID,
                state_id: data.cityID,
                ...(data.countyID && { city_id: data.countyID }),
            }),
        };
        return fetcher(`v1/address/${addressID}`, options);
    },
};

export default AccountServices;
