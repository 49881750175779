import { useContext, createContext, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useQuery } from '@tanstack/react-query';
import { CartServices } from '@services';
import { useAuth } from './AuthContext';

const CartContext = createContext();

export function useCart() {
    return useContext(CartContext);
}

export const CartProvider = ({ children }) => {
    const { currentUser } = useAuth();
    const [cookie, setCookie, removeCookie] = useCookies(['basket-cookie']);
    const [basketHash, setBasketHash] = useState(
        cookie['basket-cookie'] || null,
    );

    const updateCookie = (basketHash) => {
        setCookie('basket-cookie', basketHash, {
            maxAge: 10000 * 7,
        });
        setBasketHash(basketHash);
    };

    const getCartQuery = useQuery({
        queryKey: ['getCart'],
        queryFn: () => CartServices.getCart(currentUser, basketHash),

        onSuccess: (data) => {
            if (data.success) {
                if (!basketHash) {
                    updateCookie(data.data.basket_hash);
                }
            }
        },
    });

    const resetCart = () => {
        removeCookie('basket-cookie');
        setBasketHash(null);
        getCartQuery.remove();
    };

    const { products, total, discounts, subtotal, extra, coupon } = getCartQuery
        ?.data?.data || {
        products: [],
        warning: [],
    };

    const value = useMemo(
        () => ({
            products,
            currentUser,
            basketHash,
            getCartQuery,
            total,
            discounts,
            subtotal,
            extra,
            coupon,
            cartProductCount: products.length,
            isLoading: getCartQuery.isLoading,
            resetCart,
        }),
        [
            basketHash,
            currentUser,
            total,
            coupon,
            discounts,
            subtotal,
            extra,
            products,
            getCartQuery,
        ],
    );
    return (
        <CartContext.Provider value={value}>{children}</CartContext.Provider>
    );
};
