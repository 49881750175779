import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

const overlay = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0, .2)',
        zIndex: 999,
    },
};

const BottomModal = ({ children, isOpen, closeModal, width, height }) => (
    <StyledModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={overlay}
        contentLabel="Right Modal"
        overlayClassName="rightModal"
        className={isOpen ? 'slide-top' : 'slide-bottom'}
        closeTimeoutMS={700}
        width={width}
        height={height}
    >
        {children}
    </StyledModal>
);

export default BottomModal;

const StyledModal = styled(Modal)`
    position: absolute;
    bottom: 0;
    right: 0;
    left: auto;
    top: auto;
    width: 100%;
    height: 532px;
    z-index: 999;
    background: ${({ theme }) => theme.colors.secondary};
    border-radius: 20px 20px 0 0;
    display: flex;
    flex-direction: column;
    transition: all 0.7s ease-in-out;
`;
