import styled from 'styled-components';

export const Error = styled.h1`
    font-family: var(--futura-font);
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.sizes['4xl']};
    line-height: 70px;
    letter-spacing: 1px;
`;
export const Oops = styled.h1`
    font-family: var(--futura-font);
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.sizes['3xl']};
    line-height: 100px;
    letter-spacing: 1px;
`;
export const H1 = styled.h1`
    font-family: var(--futura-font);
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.sizes.xxl};
    color: ${({ theme, color }) =>
        color ? theme.colors[color] : theme.colors.primary};
    line-height: 44px;
    letter-spacing: 3px;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`;
export const H2 = styled.h2`
    font-family: var(--futura-font);
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.sizes.l};
    color: ${({ theme, color }) =>
        color ? theme.colors[color] : theme.colors.primary};
    line-height: 26px;
    letter-spacing: 3px;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`;
export const H3 = styled.h3`
    font-family: var(--futura-font);
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.sizes.base};
    color: ${({ theme, color }) =>
        color ? theme.colors[color] : theme.colors.primary};
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`;

export const Subtitle1 = styled.p`
    font-family: var(--futura-font);
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.sizes.base};
    color: ${({ theme, color }) =>
        color ? theme.colors[color] : theme.colors.primary};
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`;
export const Subtitle2 = styled.p`
    font-family: var(--futura-font);
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.sizes.m};
    color: ${({ theme, color }) =>
        color ? theme.colors[color] : theme.colors.primary};
    line-height: 22px;
    letter-spacing: 2px;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`;

export const Subtitle3 = styled.p`
    font-family: var(--futura-font);
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.sizes.s};
    color: ${({ theme, color }) =>
        color ? theme.colors[color] : theme.colors.primary};
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`;

export const Text = styled.p`
    font-family: var(--futura-font);
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.sizes.m};
    color: ${({ theme, color }) =>
        color ? theme.colors[color] : theme.colors.primary};
    line-height: 22px;
    letter-spacing: 1px;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`;
export const Text2 = styled.p`
    font-family: var(--futura-font);
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.sizes.s};
    color: ${({ theme, color }) =>
        color ? theme.colors[color] : theme.colors.primary};
    line-height: 22px;
    letter-spacing: 1px;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`;
export const Text3 = styled.p`
    font-family: var(--futura-font);
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.sizes.s};
    color: ${({ theme, color }) =>
        color ? theme.colors[color] : theme.colors.primary};
    line-height: 18px;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`;
export const Caption = styled.span`
    font-family: var(--futura-font);
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.sizes.xs};
    color: ${({ theme, color }) =>
        color ? theme.colors[color] : theme.colors.primary};
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`;

export const Caption2 = styled.span`
    font-family: var(--futura-font);
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.sizes.xs};
    color: ${({ theme, color }) =>
        color ? theme.colors[color] : theme.colors.primary};
    line-height: 10px;
    letter-spacing: 1px;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`;
