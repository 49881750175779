import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useInterval } from 'react-use';
import styled, { css, keyframes } from 'styled-components';

import {
    NotificationCloseIcon,
    NotificationErrorIcon,
    NotificationSuccessIcon,
    NotificationWarningIcon,
} from '@components/icons';
import { Subtitle3, Text2 } from '@components/styles/Typography';

const notificationTypes = {
    success: {
        icon: NotificationSuccessIcon,
    },
    warning: {
        icon: NotificationWarningIcon,
    },
    error: {
        icon: NotificationErrorIcon,
    },
};
const Notification = ({
    type,
    title,
    description,
    alert,
    isOpen,
    setIsOpen,
}) => {
    const router = useRouter();
    const Icon = notificationTypes[type].icon;
    const close = () => {
        setIsOpen(false);
    };

    useInterval(() => {
        if (isOpen) {
            close();
        }
    }, 5000);

    useEffect(() => {
        // subscribe
        router.events.on('routeChangeStart', close);

        // unsubscribe
        return () => router.events.off('routeChangeStart', close);
    }, [router.events]);

    return isOpen ? (
        <NotificationWrapper
            alert={alert}
            time={5000}
            data-testid="notification"
        >
            <NotificationContent>
                <NotificationIcon>
                    <Icon />
                </NotificationIcon>
                <NotificationTextWrapper>
                    <NotificationTitle>{title}</NotificationTitle>
                    <NotificationDescription>
                        {description}
                    </NotificationDescription>
                </NotificationTextWrapper>
                <button onClick={close} type="button">
                    <NotificationCloseIcon />
                </button>
            </NotificationContent>
        </NotificationWrapper>
    ) : null;
};
const show = keyframes`
  0% {
    right: -100%;
  }
  25% {
    right: 20px;
  }
  75% {
      right: 20px;
  }
  100% {
    right: -100%;
  }
`;
const mobileShow = keyframes`
0% {
    right: -100%;
  }
  25% {
    right: 0;
  }
  75% {
      right: 0;
  }
  100% {
    right: -100%;
  }
`;
const NotificationWrapper = styled.div`
    z-index: 999;
    position: absolute;
    right: 52px;
    top: 108px;
    max-width: ${({ alert }) => (alert ? '440px' : 'unset')};
    margin: ${({ margin }) => margin || '0 auto'};
    background: linear-gradient(
        91.64deg,
        rgba(255, 255, 255, 0.95) 0%,
        rgba(255, 255, 255, 0.85) 100%
    );
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
    width: fit-content;
    animation: ${({ alert }) =>
        alert
            ? css`
                  ${show} ${({ time }) => `${time}ms`} linear
              `
            : 'none'};

    @media (max-width: 1024px) {
        right: 20px;
        top: 52px;
    }
    @media (max-width: 768px) {
        width: ${({ width }) => width || 'calc(100% - 20px)'};
        top: 10px;
        left: 0;
        right: 0;
        animation: ${({ alert }) =>
            alert
                ? css`
                      ${mobileShow} ${({ time }) => `${time}ms`} linear
                  `
                : 'none'};
        max-width: 100%;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    }
`;

const NotificationContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px 4px 0 0;
    padding: 19px 15px;
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '71px'};

    button {
        border: none;
        background: transparent;
        margin-left: auto;
        cursor: pointer;
        padding: 0;
    }

    @media (min-width: 768px) {
        justify-content: flex-start;
    }

    @media (max-width: 1024px) {
        width: 100%;
    }
`;
const NotificationTextWrapper = styled.div`
    margin: 0 15px;
`;
const NotificationIcon = styled.div`
    display: block;
`;
const NotificationTitle = styled(Subtitle3)`
    margin: 0;
`;

const NotificationDescription = styled(Text2)`
    margin: 0;
`;
export default Notification;
