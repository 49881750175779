import React from 'react';

function NotificationSuccessIcon(props) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433284 8.00042 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9949 7.34939 18.9397 4.80881 17.0655 2.93455C15.1912 1.06028 12.6506 0.00508267 10 0V0ZM14.7596 8.25L9.125 13.6346C8.97933 13.7716 8.78653 13.8474 8.58654 13.8462C8.48879 13.8476 8.39173 13.8296 8.30096 13.7933C8.21019 13.757 8.12751 13.703 8.0577 13.6346L5.24039 10.9423C5.16225 10.8741 5.0987 10.7909 5.05356 10.6975C5.00842 10.6041 4.98262 10.5026 4.97772 10.399C4.97282 10.2954 4.98892 10.1919 5.02504 10.0947C5.06116 9.99752 5.11656 9.90862 5.18792 9.83337C5.25928 9.75812 5.34511 9.69808 5.44027 9.65685C5.53542 9.61562 5.63793 9.59405 5.74163 9.59345C5.84533 9.59285 5.94808 9.61322 6.0437 9.65334C6.13933 9.69346 6.22585 9.75251 6.29808 9.82692L8.58654 12.0096L13.7019 7.13461C13.8515 7.00412 14.0457 6.9365 14.244 6.94588C14.4423 6.95526 14.6292 7.04092 14.7658 7.18495C14.9024 7.32898 14.978 7.52023 14.9768 7.71872C14.9757 7.9172 14.8979 8.10756 14.7596 8.25Z"
                fill="black"
            />
        </svg>
    );
}

export default NotificationSuccessIcon;
