export default {
    signin: 'Sign In',
    signinNow: 'Sign In Now',
    name: 'Name',
    surname: 'Surname',
    email: 'E-mail',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    signup: 'Sign Up',
    signupNow: 'Sign Up Now',
    forgotPassword: 'Forgot Password?',
    forgotPasswordUpper: 'FORGOT PASSWORD?',
    userSignIn: 'Sign In',
    userSignInUpper: 'SIGN IN',
    loginWithMail: 'You can sign in with your e-mail.',
    userSignUp: 'SIGN UP',
    city: 'City',
    state: 'State',
    zip: 'Zip',
    country: 'Country',
    phone: 'Phone',
    save: 'Save',
    saveChanges: 'Save Changes',
    update: 'Update',
    cancel: 'Cancel',
    delete: 'DELETE',
    choose: 'Choose',
    resetPassword: 'Reset Password',
    gender: 'Gender',
    birthday: 'Birthday',
    addCreditCard: 'Add Credit Card',
    personalInfo: 'Personal Information',
    contactInfo: 'Contact Information',
    startDate: 'Starting Date',
    campaignStartDate: 'Campaign Starting Date',
    minAmount: 'Minimum Amount',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    campaignDetails: 'Campaign Details',
    newPasswordAgain: 'New Password (Again)',
    updatePassword: 'Update Password',
    notMember: 'Not a Member?',
    editAddress: 'Edit Address',
    address: 'Address',
    addressTitle: 'Address Title',
    account: 'Account',
    discount: 'Sale',
    changePasswordMessage:
        'If you want to change your password from which you access your account, please fill in the information below.',
    rememberMe: 'Remember Me',
    cartTitle: 'Cart',
    cartNoItem: 'No products found in the cart',
    sortBy: 'Sort By',
    more: 'More',
    less: 'Less',
    searchInCategories: 'Search In Categories',
    cardNumber: 'Card Number',
    alreadyMember: 'Already a Member?',
    noMoreProduct: 'you have displayed entire product',
    allowAgreement: 'I allow the use of my data for the purpose of the event.',
    allowPromotionSms: 'SMS',
    allowPromotionMail: 'E-posta',
    allowPromotionMessage: 'I give',
    allowPromotionEMail: 'I give',
    clearConsent: 'Clear Consent',
    clearConsentText:
        'to sending Commercial Electronic Messages via SMS, conducting surveys and feedback, and processing my personal data in this context.',
    clearConsentText2:
        'to sending Commercial Electronic Messages via e-mail, conducting surveys and feedback, and processing my personal data in this context.',
    featuredCategories: 'FEATURED CATEGORİES',
    featuredProducts: 'FEATURED PRODUCTS',
    trendsofWeek: 'TRENDS OF THE WEEK',
    headerNoticeHelp: 'HELP',
    headerNoticeLng: 'English',
    homepageNotification: ' WORLDWIDE ',
    homepageFree: 'FREE ',
    homepageShip: 'SHIPPING',
    mobileMenuHomePage: 'Homepage',
    mobileMenuSearch: 'Search',
    mobileMenuFavorites: 'Favorites',
    mobileMenuAccount: 'Account',
    mobileMenuMenu: 'Menu',
    mobileMenuCart: 'Cart',
    searchModalTitle: 'LAST SEARCH',
    footerKeiKei: 'KeiKei',
    footerAboutUs: 'About Us',
    footerContact: 'Contact',
    footerFaq: 'Faq',
    footerExplore: 'Explore',
    footerCampaigns: 'Campaigns',
    footerDiscount: 'Discount',
    footerAllCategories: 'All Categories',
    footerSocialMedia: 'Follow',
    footerFacebook: 'Facebook',
    footerInstagram: 'Instagram',
    footerYoutube: 'Youtube',
    footerHelp: 'Help',
    footerCookie: 'Cookie Policy',
    footerTermsConditions: 'Terms and Conditions',
    footerPrivacyPolicy: 'Privacy Policy',
    footerChangeReturn: 'Change and Return',
    footerDeliveryPayment: 'Delivery and Payment',
    footerNewseller: 'E-NEWSELLER',
    footerJoinUs:
        'Join our e-newsletter, get 10% discount on your first purchase!',
    footerDownloadApp: 'Download App',
    footerAllRightsReserved: '© KeiKei all rights reserved.',
    footerSecureShopping: 'SECURE ONLINE SHOPPING',
    footerRightsandAssignments: 'Rights and Assignments',
    productName: 'Yellow Short Dress',
    productSizeTitle: 'Size',
    productAmountTitle: 'Amount',
    productAmount: '1',
    productPrice: '290 TL',
    currency: '€',
    goToCart: 'GO TO CART',
    buyNow: 'CHECKOUT',
    searchDesktopPlaceholder: 'Search site and products',
    searchMobilePlaceholder: 'Search for a product or category',
    discountContainerText: 'in Cart',
    discountAmount: '50%',
    addToBasket: 'Add To Cart',
    estimatedDelivery: 'Estimated Delivery',
    details: 'Details',
    returnPolicy: 'Return Policy',
    productColor: 'Color',
    productSize: 'Size',
    productSizeTable: 'Size Table',
    productInfoTableCategory: 'Category',
    productInfoTableStyle: 'Style',
    productInfoTableFabric: 'Fabric',
    productInfoTableLenght: 'Lenght',
    productInfoTableLocation: 'Location',
    productInfoTableComposition: 'Composition',
    productInfoTableBrand: 'Brand',
    productNotifyTitle: 'Your Request Has Been Received.',
    productNotifyMe: 'Notify Me When Back In Stock',
    favNotifyMe: 'Notify Me',
    productNotifyDescription:
        'An e-mail will be sent to your registered e-mail address when the product is in stock.',
    notFoundTurnHome: 'HOMEPAGE',
    notFoundReport: 'REPORT PROBLEM',
    pageNotFoundTitle: 'THE PAGE YOU ARE TRYING TO REACH IS NOT AVAILABLE.',
    pageNotFoundText:
        'You can try again later, navigate to any page using the menu, or contact us about the problem.',
    couponCode: 'Coupon Code',
    expiryDate: 'Expiry Date',
    addNewCard: 'Add New Card',
    addNewAddress: 'Add New Address',
    totalProducts: 'Total Products',
    pickSize: 'Select Size',
    outOfStock: 'Out of Stock',
    addToCart: 'Add to Cart',
    searchInFavorites: 'Search in My Favorites',
    favorites: 'Favorites',
    detail: 'Detail',
    cancelOrder: 'Cancel Order',
    total: 'Total',
    paymentDetails: 'Payment Details',
    deliveryAddress: 'Delivery Address',
    sameTheBill: 'Same the Bill Address',
    updateDeliveryAddress: 'Update Delivery Address',
    easyReturn: 'Easy Return',
    seeDeliveryAddress: 'See Delivery Address',
    FAQTitle: 'Frequently Asked Questions',
    FAQShopping: 'SHOPPING-ORDER',
    FAQMembership: 'MEMBERSHIP',
    FAQShipping: 'CARGO & DELIVERY',
    FAQRebate: 'REBATE & CHANGE',
    headerProduct: 'Product',
    noSearchResult: 'There is no product in your search criteria.',
    searchAgain: 'Search Again',
    aboutUs: 'About Us',
    aboutKeiKei: 'ABOUT KeiKei',
    productMetaText3:
        "Stylish and trendy dress models with advantageous price options are available at keikei.com. Don't miss out on KeiKei's deals with affordable prices and fast shipping options!",
    productMetaText2:
        "Captivating models in the latest trends and fashion colors of women's dresses are available at keikei.com. Don't miss out on our advantageous prices and fast shipping options!",
    aboutUsText:
        'KeiKei offers the latest fashion trends for women at the most reasonable prices. It appeals to every woman`s taste with its variable and rich collection, which is added to new models every day. Only from the internet, en.keikei.com  brings the clothes you want to your door in an average of 3 days.',
    aboutUsMission: "Our Mission; every single women say 'Fashion Now KeiKei'.",
    cookiePolicy: 'Cookie Policy',
    cookiePolictText1:
        'Cookies are small files that are placed on your desktop, notebook or mobile device by a website you visit. From this we can, recognize whether there has already been a connection between your device and server.',
    cookiePolicyText2:
        'We use cookies to make our website easier to use, to deliver a personalized experience on our websites, and to better tailor our products, services and websites to your interests and needs. Cookies are used to help speed up your future activities and your experience on Unilever websites. we use cookies to compile anonymous, aggregated statistics that allow us to understand how people use our websites and to help us improve their structure and content and also help us measure the effectiveness of advertising campaigns on our website.',
    cookiePolicyText3: 'We categorize cookies as follows:',
    cookiePolicyText4:
        'These cookies are necessary for the functions of the web pages. Without these cookies we would not be able to offer you services.',
    cookiePolicyText5:
        'These cookies facilitate the operation and improve the functions of the web pages. For example, we store your language settings in functional cookies.',
    cookiePolicyText6:
        'These cookies collect information about how you use our website.',
    cookiePolicyText7:
        'These cookies are set by third parties, e.g. social networks such as Twitter, Facebook and Google+',
    cookiePolicyText8:
        'We would like to tailor the content of our websites as precisely as possible to your interests and in this way improve our offer for you. In order to identify usage preferences and particularly popular areas of the websites, we use Google Analytics as analysis tool.',
    cookiePolicyText9:
        "When using these analysis tools, data may be transferred to servers located in the USA and processed there. Please note the following: In the USA, the European Union considers that there is no 'adequate level of protection' for the processing of personal data in accordance with EU standards. Furthermore, we would also like to inform you that your IP-address transmitted by your browser within the framework of Google Analytics has not been combined with any other data from Google.",
    cookiePolicyText10:
        "Our website also uses cookies to enable the 'advertisement technology' in order to offer you advertisements that might draw your interest when you visit the browsers or mobile site of the electronic trade platforms operated by KeiKei and/or websites where KeiKei places advertisements. Advertisement technology uses information from your previous visits to the website, or mobile site and other websites, or mobile sites where KeiKei places advertisements. A unique third-party cookie may be installed on your browser while offering these advertisements, in order for KeiKei to be able to recognize you.",
    cookiePolicyText11: 'The cookie information that we use are as follows;',
    cookiePolicyText12: 'Session Cookies',
    cookiePolicyText13: 'KeiKei',
    cookiePolicyText14: "Cookies used to control the user's session.",
    cookiePolicyText15:
        ' To maximize your user experience during your visit to our websites, to your preferences providing you with appropriate, personalized content, following our own performance, our website ensuring your security and privacy during your visit, storing your username and password information, and including but not limited to providing more relevant advertising to your interests, We use cookies for many different purposes.',
    cookiePolicyText16: 'All pages',
    cookiePolicyText17: 'Permanent',
    cookiePolicyText18: 'Performance Cookies',
    cookiePolicyText19:
        " Google Analytics is on visitors' websites and website and application what they do in their applications Google's free to help its owners understand analysis tool. Without identifying visitors gather information and website usage statistics to Google use a set of cookies to report. Google Analytics The main cookie used by is the '_ga' cookie.",
    cookiePolicyText20:
        ' Google Analytics allows website usage statistics In addition to reporting, the advertising described above along with some of its cookies on Google properties Google More relevant ads to you like search) and across the web help show and the ads we show It can also be used to measure interactions.',
    cookiePolicyText21:
        ' A better user experience to present and be of interest to you that ads reach you and ensure measurement is used for the purpose.',
    cookiePolicyText22:
        'Web browsers are set to automatically accepts cookies by default. You may change these settings to block cookies or to alert them when a cookie is sent to your device. Unfortunately, if you do so, we would like to inform you that all the features of the website may not be fully utilized.',
    cookiePolicyTitle1: 'Essential cookies:',
    cookiePolicyTitle2: 'Functional cookies:',
    cookiePolicyTitle3: 'Performance cookies: ',
    cookiePolicyTitle4: 'Third-party cookies:',
    cookiePolicyTitle5: 'Cookie Name:',
    cookiePolicyTitle6: 'Provider:',
    cookiePolicyTitle7: 'Purpose:',
    cookiePolicyTitle8: 'Website:',
    cookiePolicyTitle9: 'Expiration:',
    cookiePolicyTitle10: 'Categories:',
    cookiePolicyTitle11: 'Cookie Management and Refuse',
    termsConditions: 'TERMS & CONDİTİONS',
    termsConditionstitle1:
        '1.Privacy Policy Regarding Protection & Processing of Personal Data',
    termsConditionstitle2: '2.Intellectual Property Rights',
    termsConditionstitle3: '3.Links to Other Web Sites',
    termsConditionstitle4: '4.Links to www.keikei.com ',
    termsConditionstitle5: '5.Disclaimers on Content ',
    termsConditionstitle6: '6.Our Business Policy',
    termsConditionstitle7: '7.Governing Law',
    termsConditionstitle8: '8.Errors and inaccuracies ',
    termsConditionsText1:
        'Welcome to our web site (www.keikei.com ). These Terms and Conditions govern the access to and use of www.keikei.com. The access to and use of this web site as well as the purchase of products on www.keikei.com are based on the assumption that these Terms and Conditions have been read, understood and accepted by you. This website is managed and maintained by KeiKei Tekstil ve Giyim Sanayi Ticaret Anonim Sirketi residing at Dolapdere Caddesi Kucuk Bayir Sokak No: 119/A 34272 Sisli - Istanbul / TURKEY',
    termsConditionsText2:
        'If you need further information or assistance, go to the Contact Page where you will find information on orders, shipping, refunds and returning products purchased on www.keikei.com, a registration form, tips and other general information on the services provided by www.keikei.com',
    termsConditionsText3:
        'KeiKei, may amend or simply update all or part of these Terms and Conditions. Any amendment or update of the Terms and Conditions shall be posted for our users on the Home Page of www.keikei.com as soon as such amendments or updates have been made and shall be binding as soon as they are published on the web site in this section. Therefore, you should regularly access this section on the web site in order to check the publication of the most recent and updated Terms and Conditions of www.keikei.com. If you do not agree to all or part of the www.keikei.com’s Terms and Conditions, please do not use our web site.',
    termsConditionsText4:
        "The access to and use of www.keikei.com, including display of web pages, communication with KeiKei, downloading product information and making purchases on the web site, are carried out by our users exclusively for personal purposes, which should in no way be connected to any trade, business or professional activity. Remember that you will be liable for your use of www.keikei.com and its contents. KeiKei shall not be considered liable for any use of the web site and its contents made by its users that is not compliant with the laws and regulations in force, without prejudice to KeiKei's liability for intentional torts and gross negligence.",
    termsConditionsText5:
        'In particular, you will be liable for communicating information or data which is not correct, false or concerning third parties (in the event such third parties have not given their consent) as well as for any improper use of such data or information.',
    termsConditionsText6:
        'We recommend that you read the Privacy Policy Regarding Protection & Processing of Personal Data (“Privacy Policy”) which also applies in the event that users access to www.keikei.com and use the relevant services without making purchases. The Privacy Policy will help you understand how and for what purposes www.keikei.com collects and uses your personal data.',
    termsConditionsText7:
        'All content included on this Site, such as works, images, pictures, dialogues, music, sounds, videos, documents, drawings, figures, logos, menus, web pages, graphics, colours, schemes, tools, fonts, designs, diagrams, layouts, methods, processes, functions and software (collectively, “Content”), is the property of KeiKei and is protected by national and international copyright and other intellectual property laws. You may not reproduce, publish, distribute, display, modify, create derivative work from, or exploit in any way, in whole or in part, the Content without the prior express written consent of KeiKei.',
    termsConditionsText8:
        'KeiKei shall have the exclusive right to authorize or prohibit in their sole discretion any reproduction, publication, distribution, display, modification, creation of derivative work from, or exploitation in any way of, in whole or in part, the Content. KeiKei shall have the right, at any time, to claim the authorship of any Content posted on this Site and to object to any use, distortion or other modification of such Content.',
    termsConditionsText9:
        'Any reproduction, publication, distribution, display, modification, creation of derivative work from, or exploitation in any way of, the Content expressly authorized in writing by KeiKei shall be carried out by you for lawful purposes only and in compliance with all applicable laws.',
    termsConditionsText10:
        'www.keikei.com  may contain links to other web sites which are in no way connected to www.keikei.com. KeiKei does not control or monitor such third party web sites or their contents. KeiKei shall not be held liable for the contents of such sites and/or for the rules adopted by them in respect of, but not limited to, your privacy and the processing of your personal data when you are visiting those web sites. Please, pay attention when you access these web sites through the links provided on www.keikei.com and carefully read their terms and conditions and their privacy policies. Our Terms and Conditions and the Privacy Policy do not apply to the web sites of third parties.',
    termsConditionsText11:
        "Please, contact to our Customer Support Team, if you are interested in linking the Home page of www.keikei.com and other web pages which can be publicly accessible. KeiKei is entitled to object to certain links to its web site in the event that the applicant who intends to activate links to www.keikei.com has, in the past, adopted unfair commercial or business practices which are not generally adopted or accepted by the market operators, or has made unfair competition activities vis-à-vis the Provider or the latter's suppliers, or when the KeiKei fears that such practices or such activities might be adopted by the applicant in the future. In any event, it is forbidden to post deep links (such as deep frames) to www.keikei.com or to use unauthorized meta-tags without the KeiKei consent.",
    termsConditionsText12:
        'KeiKei does not warrant that the contents of the web site are appropriate or lawful in other Countries outside Turkey. However, in the event that such contents are deemed to be unlawful or illegal in some of these Countries, please do not access this web site and, where you nonetheless choose to access it, we hereby inform you your use of the services provided by www.keikei.com shall be your exclusive and personal responsibility. KeiKei has also adopted measures to ensure that the content of www.keikei.com is accurate and does not contain any incorrect or out-of-date information. However, KeiKei cannot be held liable for the accuracy and completeness of the content, except for its liability for tort and gross negligence and as otherwise provided for by the law. Moreover, KeiKei cannot guarantee that the web site will operate continuously, without any interruptions and errors due to the connection to the Internet. In the event of any problem in using our web site please contact our Customer Support Team. A KeiKei representative will be at your disposal to assist and help you to restore your access to the web site, as far as possible.',
    termsConditionsText13:
        'At the same time, please contact your internet service provider or check that each device for internet connection and access to web content is correctly activated, including your Internet browser. The dynamic nature of the Internet and web content may not allow www.keikei.com to operate without any suspensions, interruptions or discontinuity due to updating the web site. The Provider has adopted adequate technical and organizational security measures to protect services on www.keikei.com, integrity of data and electronic communications in order to prevent unauthorized use of or access to data, as well as to prevent risks of dissemination, destruction and loss of data and confidential/non confidential information regarding users of www.keikei.com, and to avoid unauthorized or unlawful access to such data and information.',
    termsConditionsText14:
        "The Provider has adopted a business policy; its mission consists of selling products through its services and its web site to 'B2C consumer' only. 'B2C Consumer' shall mean any natural person, legal person, wholesaler etc.",
    termsConditionsText15:
        'These Terms and Conditions are governed by laws of Republic of the Turkey.',
    termsConditionsText16:
        'Our goal is to provide complete, accurate, and up-to-date information on our website. Unfortunately, it is not possible to ensure that any website is completely free of human or technological errors. This website may contain typographical mistakes, inaccuracies, or omissions.',
    privacyPolicy:
        'KİŞİSEL VERİLERİN İŞLENMESİ VE KORUNMASINA İLİŞKİN GİZLİLİK POLİTİKASI',
    KVKK: 'KVKK Personal Data Protection Authority',
    privacyPolicyTitle1:
        '1. Which Categories of Personal Data Do We Collect from You?',
    privacyPolicyTitle2:
        'We are collecting your following personal data in case you log in for KeiKei Website (https://www.keikei.com/ ) [“Website”]',
    privacyPolicyTitle3:
        'We are collecting your following personal data while you are shopping as a guest without log in the website:',
    privacyPolicyTitle4:
        'We are collecting your following personal data, if you give us prior consent to send electronic personalized/commercial messages:',
    privacyPolicyTitle5:
        '2. What are the Purposes and Legal Grounds for Processing of Your Personal Data?',
    privacyPolicyTitle6:
        'We process your personal data for the following purposes:',
    privacyPolicyTitle7:
        '3. What are the Methods for Collecting of Your Personal Data?',
    privacyPolicyTitle8:
        '4. To Whom and For What Purposes Do We Transfer Your Personal?',
    privacyPolicyTitle9: '5. What Are Your Rights within scope of the LPPD?',

    privacyPolicyText1: 'Dear Customers, Visitors, and Subscribers,',
    privacyPolicyText2:
        "As KeiKei Tekstil ve Giyim Sanayi Ticaret Anonim Sirketi ('KeiKei' or 'Company'), we value the protection of your personal data and your right of privacy. This Privacy Policy Regarding Protection and Processing of Personal Data (“ Privacy Policy”) explains how your personal data is collected and processed, what kind of data is collected, how that personal data is used, what your rights are on your personal data pursuant to Law on the Protection of Personal Data No. 6698 (“LPPD”) regarding the services offered and presented to you by KeiKei as a Data Controller.",
    privacyPolicyText3:
        'We collect personal data from the below data subject categories within the scope our activities and services provided by us. In each case, we process the personal data in compliance with the principles and requirements of the LPPD and purposes set out in this Privacy Policy.',
    privacyPolicyText4:
        '· Personal Identifying Information (name, surname, date of birth)',
    privacyPolicyText5:
        '· Contact Information (address, e-mail address, phone number)',
    privacyPolicyText6: '· Gender',
    privacyPolicyText7:
        '· Details of your use the Website (your behavior, transactions, preferences, visited products, etc.)',
    privacyPolicyText8:
        '· Shopping Information (date, amount, content of the shopping and payment method etc.)',
    privacyPolicyText9:
        '·  If it is indicated in the invoice, Turkish ID Number, tax number, partnership company information',
    privacyPolicyText10:
        '· If you make your shopping with a credit card, your credit card information (Your credit card information is transferred to relevant payment institution without recording by KeiKei)',
    privacyPolicyText11:
        '· Your marketing information (date of birth; gender; using of the Website; demographic member/user/customer information such as shopping habits, address; shopping habits preferences for all kinds of products and services including clothing; likes and related comments; campaigns, surveys and their content; payment methods (such as cash, credit card etc.) and payment details (the instalment amount); former and new mobile/home/work phone numbers, e-mail addresses; approach to electronic commercial and other communications; cookies (cookies, web browser signs-information, IP, beacon, cabled-wireless connection etc.); ad identifier information; location data provided that the location sharing permission is granted on the internet]',
    privacyPolicyText12:
        "'Processing of personal data' means that collection, storage, update, categorization, and transfer of your personal data to third parties by KeiKei as 'Data Controller' in accordance with the LPPD.",
    privacyPolicyText13: 'Purposes',
    privacyPolicyText14: 'Legal Basis for Processing',
    privacyPolicyText15:
        '· To conclude a contact for the transactions for registration and sales on the Website;',
    privacyPolicyText16: '· to send the order to your address;',
    privacyPolicyText17: '· to issue an invoice;',
    privacyPolicyText18: '· to receive payment;',
    privacyPolicyText19:
        '· to solve your problems and complaints when you contact with us and to contact you with regard to this and to execution;',
    privacyPolicyText20:
        '· To analyze and evaluate requests of exchange, return and transfer of the product',
    privacyPolicyText21:
        '· To provide call center quality standards and service/process security for phone calls;',
    privacyPolicyText22:
        '· To better manage, follow and report all customer relations processes; and',
    privacyPolicyText23:
        '· other purposes relating to the performance of the contact.',
    privacyPolicyText24:
        '· Based on the legal basis that data processing is necessary for the execution and performance of a contract or agreement',
    privacyPolicyText25:
        '· To fulfill our obligations arising from the legislation within the scope of the activities of the relevant departments in KeiKei,',
    privacyPolicyText26:
        '· To fulfill our obligation to provide information and documents to public authorities and judicial authorities, ',
    privacyPolicyText27:
        '· To fulfill out other legal obligation especially information security',
    privacyPolicyText28:
        '· Based on the legal basis for fulfillment of the legal obligation ',
    privacyPolicyText29:
        '· To exercise the right of litigation, defense and objection against governmental agencies such as courts, enforcement offices, arbitration committee in disputes arising from the contact within the scope of the activities of the relevant departments of KeiKei ',
    privacyPolicyText30:
        '· Based on the legal basis that data processing is mandatory for the establishment, use or protection of a right',
    privacyPolicyText31:
        'We also disclose to you that we will not use your personal data for any other commercial purposes other than those listed above and other applicable legislation.',
    privacyPolicyText32:
        'We as KeiKei may collect your personal automatically in following ways; by storing the data that you shared with us into our systems when you shop or register as a member on the Website and/or when you use of your fixed-mobile internet and communication devices; and/or when we contact you via phone and/or by storing all kinds of shopping, payment, delivery, transaction, survey filling, registration and behavioral transactions on the Website',
    privacyPolicyText33:
        'Your personal data may be transferred to business associates, group companies, suppliers, shareholders of our Company and to public bodies and individuals legally authorized for the purposes mentioned above within the scope of personal data processing conditions and purposes pursuant to the LPPD.',
    privacyPolicyText34:
        'In this regards, we as KeiKei may transfer your personal data',
    privacyPolicyText35:
        '· with to our domestic and international business partners and service providers such as call centers, providing personal data collection service through devices, consulting on marketing, providing database service, providing electronic messaging tool and consulting firms) in order to receive services such as information technologies, marketing activities or consultancy that requires expertise;',
    privacyPolicyText36:
        '· with our business partners that we receive delivery and courier services in order to send your shopping order made on the Website and any other product requests,',
    privacyPolicyText37:
        '· with public authorities and judicial authorities to fulfill our obligation to provide information and documents and to use our legal rights such as filing a lawsuit and serving a reply; and',
    privacyPolicyText38:
        '· If you make the payment with your credit card, we may transfer your credit card information without holding in our records to third party service providers such as bank, electronic payment institutions etc.; and',
    privacyPolicyText39:
        '· If you would like to receive campaign notifications via SMS or e-mail, we may transfer your personal data with our domestic and international business partners and electronic messaging service provider in order for planning and executing of specific sales and marketing activities; customizing products and services in accordance with your tastes, usage habits and needs; proposing campaign and offers to the customers by evaluating their shopping histories and sending e-mails messages to the customers or potential customers prior to their shopping histories and customizing, advertising and planning and execution of activities related to the products and services according to the tastes, usage habits and needs of the individuals for the purposes of establishing or increasing the commitment to the products and services offered by our Company, executing satisfactory and commitment survey.',
    privacyPolicyText40:
        'According to Article 11 of the LPPD, personal data owners have a right to;',
    privacyPolicyText41:
        'a) Learn whether your personal data is being processed;',
    privacyPolicyText42:
        'b) Request information as to the possibility of processing of your data,',
    privacyPolicyText43:
        'c) Learn the purposes of such processing of personal data and whether processed data is being used in accordance with these purposes,',
    privacyPolicyText44:
        'd) Learn whether your personal data is being transferred within the country or to abroad,',
    privacyPolicyText45:
        'e) Request amendment in case your personal data processed is incomplete or inaccurate and request that the process carried out in this context to be notified to the third parties to whom the personal data is transferred,',
    privacyPolicyText46:
        'f) Pursuant to Article 7 of the LPPD, request the deletion or termination of your personal data in the event that the reasons for its processing are no longer present, despite having been processed in accordance with the provisions of the LPPD and other related laws, and request that the process carried out in this context to be notified to the third parties to whom the personal data is transferred,',
    privacyPolicyText47:
        'g) Request that the parties to whom his/her data is transferred are informed of the transactions carried out as per paragraphs (d) and (e),',
    privacyPolicyText48:
        'h) Object to the occurrence of a result to the detriment of the person yourself, by means of analyzing the processed data exclusively through automated systems,',
    privacyPolicyText49:
        'i) Request compensation for the damages in case the person incurs damages due to unlawful processing of your personal data.',
    privacyPolicyText50:
        'You may exercise your rights within the scope of the LPPD and GDPR in writing.',
    privacyPolicyText51:
        'Such notifications could be sent to our Company’s headquarter address Dolapdere Cd. Kucukbayir Sk. 119/A Sisli / Istanbul-Turkey',
    privacyPolicyText52:
        'You may use the Application Form at our website for the purposes. Please find below the link for such form:',
    privacyPolicyText53: 'Application Form',
    privacyPolicyText54:
        'Your inquiries noted in your application shall be evaluated within the shortest time and within thirty (30) days at the latest.',

    changeAndReturn: 'DELIVERY, WITHDRAWAL, REMITTAL, RESTITUTION PROVISIONS',
    changeAndReturnTitle:
        'DELIVERY, WITHDRAWAL, REMITTAL, RESTITUTION PROVISIONS',
    deliveryAndPayment: 'DELIVERY AND PAYMENT',
    deliveryAndPaymentTitle: 'Distance Sales Contract',
    distanceSellingPreInfo: 'PRELIMINARY INFORMATION FORM',
    send: 'Send',
    forgotMyPassword: 'I Forgot My Password',
    resetMyPassword: 'Reset My Password',
    passwordRequirement:
        'Your password must be at least 7 characters and contain letters and numbers.',
    forgotPasswordSendMail:
        'Please enter your email and send it.We will send you a link to set a new password',
    addressInfo: 'ADDRESS INFO',
    discountTextCart: '',
    enterDeliveryAddress: 'Enter the address you want delivery to.',
    changeAddress: 'Change Address',
    billingAddress: 'BILLING ADDRESS',
    deliveryAndBillingAddressSame: 'Bill to the same address.',
    paymentOptions: 'PAYMENT OPTIONS',
    enterCardInfo: 'Enter card information',
    creditCardNumber: 'Card Number',
    validityDate: 'Validty Date',
    CVV: 'CVV',
    confirmSaveCardInfos: 'I approve the saving of my card information.',
    want3dSecure: 'I want to pay with 3D Secure Payment System.',
    installmentOptions: 'INSTALLMENT OPTIONS',
    selectInstallmentOption:
        'Select the installment option suitable for your card.',
    installmentNumber: 'INSTALLMENT NUMBER',
    monthlyPayment: 'Monthly Payment',
    cardInfo: 'CARD INFO',
    payWithAnotherCard: 'PAY WITH ANOTHER CARD',
    completePayment: 'COMPLETE PAYMENT',
    add: 'Add',
    edit: 'Edit',
    goOrdersPage: 'GO ORDERS PAGE',
    estimatedDeliveryDate: 'Estimated Delivery Date',
    color: 'Color',
    en: 'English',
    tr: 'Turkish',
    GO: 'GO',
    cartStockOut: 'The product you selected is out of stock.',
    contact: 'CONTACT',
    factoryAddressTitle: 'Factory',
    headOfficeAddressTitle: 'Head Office',
    contactUs: 'Contact Us',
    whatsapp: 'WhatsApp',
    contactWorkingHours: 'Working Hours',
    contactInfoText:
        'You can send us your questions,  opinions and suggestions about KeiKei products and all other issues via the form below.',
    callCenter: 'Call Center',
    monday: 'Monday',
    friday: 'Friday',
    sort: 'Sort',
    listingView: 'Listing View',
    product: 'Product',
    filter: 'Filter',
    readMore: 'Read More',
    hasDiscountCouponCode: 'Discount Coupons',
    myCreditCards: 'Saved Cards',
    myAdress: 'My Addresses',
    addresses: 'Addresses',
    myAccount: 'Account',
    customizeCookie: 'Customize',
    customizePromotionCookie: 'Customize Promotion Cookies',
    keikeiAds: 'KeiKei Ads',
    thirdPartyAds: 'Approved Third Party Ads',
    customizeThirdPartyAds: 'Customize Approved Third Party Ads',
    acceptCookie: 'Accept Cookies',
    advertisingCookies: 'Advertising and Optional Cookies',
    saveCustomCookie: 'Save Custom Cookies',
    cookiePolicyText:
        'In order to offer you a better experience on our website cookies are used. Detailed information:',
    advertisingCookieText:
        'We also use cookies on KeiKei to serve certain types of advertisements, including specific advertisements about your interests, and for other promotional, statistical, and marketing purposes. Approved third parties you interact with while using KeiKei services may also set cookies. Third parties use cookies during the process of delivering content, including advertisements related to your interests, measuring the effectiveness of their advertisements, and running services on behalf of KeiKei.\n' +
        '\n' +
        'Below, you can accept, reject, or change your previous preferences for KeiKei and third-party cookies, collectively or individually.',
    consentPurposes: 'Consent Purposes:',
    customCookiePolicyText:
        'We use cookies in accordance with legal regulations to improve your shopping experience and make our services better. If you continue to visit our website and shop, we will continue to use operational and mandatory cookies. In terms of advertising and optional cookies, we will continue to use only the cookies you allow. You can use the links below to edit your cookie preferences or you can review our Cookie Policy for detailed information about cookies. If you want to learn our Cookie Policy',
    consentPurpose1: 'Store and/or access information on the device',
    consentPurpose2: 'Choose core ads',
    consentPurpose3: 'Choose personalized ads',
    consentPurpose4: 'Measure ad performance',
    consentPurpose5: 'Conduct market research to create audience analysis',
    specialPurposes: 'Special Purposes:',
    specialPurpose1: 'Ensure security, prevent fraud and fix bugs',
    specialPurpose2: 'echnically serve ads or content',
    features: 'Features',
    feature1: 'Match and combine offline data sources',
    feature2: 'Connect different devices',
    clickHere: 'click here',
    apply: 'apply',
    okay: 'tamam',
    resetLinkSended: 'Reset link sent successfully.',
    userNotFound: 'User Not Found.',
    incorrectInformation: 'Your e-mail address and/or password is incorrect.',
    emailUsedBefore: 'This e-mail has been used before.',
    signUpWith: 'Sign Up with',
    loginWith: 'Login With',
    passwordChanged: 'Password changed successfully!',
    infoChanged: 'Informations changed successfully!',
    checkPassword: 'Check Password!',
    favoriteProduct: 'Favorite Product',
    chooseASize: 'Size',
    addAddress: 'Add Address',
    applyCouponInBasket: 'Apply To Products In Basket',
    myOrders: 'My Orders',
    myFavorites: 'My Favorites',
    myAddresses: 'My Addresses',
    myDiscountCoupons: 'Discount Coupons',
    savedCreditCards: 'Saved Credit Cards',
    changePassword: 'Change Password',
    profileInformations: 'Profile Informations',
    help: 'Help',
    logOut: 'Log Out',
    cartDelete: 'DELETE THIS PRODUCT',
    cartDeleteText: 'Do you want to remove this product from your?',
    cartEnterDiscountCode: 'Discount Code',
    noDiscountCode: 'You do not have a discount coupon',
    cartDiscountText: 'To get an',
    cartDiscountAmount: 'extra 10% discount in the cart',
    cartAdd: 'add',
    cartWorthProduct: 'worth of product',
    cartSummary: 'CART SUMMARY',
    cartTotal: 'TOTAL',
    cartDeleteAndFav: 'DELETE & ADD TO FAVORITES',
    cartDeleteAndFavText:
        'Do you want to remove this product from your cart and add to favorites?',
    cartNoProduct: 'You have no products in your cart!',
    cartAddBox: 'ADD BOX',
    cartGiftBoxText:
        'Would you like to send your loved ones their orders in special KeiKei Boxes?',
    cartChooseDiscountCode: 'Choose Discount Code',
    cartProductTotal: 'Product Total',
    shipping: 'Shipping',
    membershipAgreement: 'Membership Agreement',
    PersonalDataProtectionAuthority: 'Personal Data Production Form',
    membershipInfo:
        'WITHIN THE SCOPE OF WEBSITE MEMBERSHIP CLARIFICATION TEXT ON THE PROCESSING OF PERSONAL DATA',
    membershipInfoMetaTitle: 'Pre-subscription Information',
    metaTitle: ' Models and Prices ',
    categoryMetaText:
        "Stylish and trendy dress models with advantageous price options are available at keikei.com. Don't miss out on KeiKei's deals with affordable prices and fast shipping options!",
    categoryMetaText2:
        "Captivating models in the latest trends and fashion colors of women's dresses are available at keikei.com. Don't miss out on our advantageous prices and fast shipping options!",
    facebookText:
        'Facebook is a social networking and social media site that allows you to connect with people. It offers a wide range of services for businesses and individuals to grow their audience and expand their reach.',
    pixelText:
        'Facebook Pixel is a service mainly used for conversion tracking, optimization and remarketing.',
    gAnalyticsText:
        'Google Analytics is a web analytics service that monitors and measures website traffic. It has a set of analytics features with advanced functions that provide useful information to webmasters. It is also used to set Market Analysis and Conversion targets after integrating with AdWords.',
    gTagManagerText:
        'Google Tag Manager is an enterprise-grade tag management solution for efficient analytics. It supports third-party analytics and offers advanced tag management technologies.',
    gReMarketingText:
        'The Google Remarketing Tag helps to easily create remarketing lists by allowing a single tag to be placed on all pages on the site.',
    payWithSavedCard: 'Pay with saved card',
    agreementAccept1:
        'I only consent to the processing of my country, date of birth, contact and gender information that I have specified/filled',
    agreementAccept2: 'in for the purposes specified in the',
    showDiscountedProducts: 'Show Discounted Products',
    howDoIReturn: 'How Do I Return?',
    returnDescription:
        'Products sold on our site are in the category of daily, disposable products and evening dresses. Therefore, we cannot accept your return requests. Thank you for your understanding.We do not have an arbitrary return option for our products. However, we can exchange it with a different product you wish. If there is a price difference, do not forget to send that price difference to us.In order for you to make a change, you must contact us at +90 850 259 1441 / +90 212 248 81 11 phone numbers, support@keikei.com e-mail address or keikei@hs01.kep.tr kep address within 24 hours from the date you receive for exchange. You have to state your request.If the notification is not made in due time, you will unfortunately lose your right to exchange, we are sorry in advance for this.If you reach us within the period, we will give you the information of the courier company we have contracted with and the exchange code.Within 10 days after receiving the code, you must deliver the product you want to exchange and any attachments, accessories, packaging, invoice, if any, warranty certificate, user manual and other documents of the product, complete and undamaged, to the address specified below to the Company.All costs that may arise during the sending of the products for exchange will be yours, including the shipping fee. In case the delivery address is a foreign address, all kinds of shipping costs and customs and other tax / duty costs in case of arising will also be covered by you. Please make exchange considering this situation.',
    returnPolicyDescription:
        'The return period is 14 days from the delivery date. The product you will return must be undamaged, unused and not damaged as a result of misuse. If products reach us used, worn or washed, we may have to send them back to you. In this case, no refund will be made. You must return the product/products you want to return, together with all accessories and the original box, along with the waybill.',
    home: 'Home Page',
    and: 'and',
    agreementAccept: 'were accepted',
    orderReceived: 'YOUR ORDER HAS BEEN RECEIVED',
    orderNumber: 'Order Number',
    colour: 'Colour',
    orderDate: 'Order Date',
    orderSummary: 'Order Summary',
    buyer: 'Buyer',
    amount: 'Amount',
    orderDetail: 'Order Information',
    searchResult: 'Search Result',
    minCharWarning: ' It must contain at least 2 characters',
    passwordMinCharWarning: 'Password must be at least 5 characters',
    enterValidMail: 'Please enter a valid e-mail address.',
    passwordTooLong: 'Password must be at max 32 characters.',
    validPhoneNumber: 'Phone number is not valid.',
    addressTitleTooLong: 'Address title must be at max 16 characters.',
    addressTitleTooShort: 'Passwords are not same.',
    mustAcceptTerms: 'You must accept terms.',
    requiredMessage: 'This field is required.',
    giftBox: 'Gift Box',
    cartTypeDiscountCode: 'Type a coupon code',
    productDiscount: 'Product Discount',
    coupon: 'Coupon',
    discountCoupon: 'Discount Coupon',
    loadMore: 'Load More',
    last: 'Last',
    removeBox: 'Remove Box',
    selectCoupon: 'Select Coupon',
    cartConfirm: 'Checkout',
    pay: 'Confirm Cart',
    enterCoupon: 'Enter Discount Code',
    addressTooShort: 'Address must be at least 8 characters.',
    zipCodeTooShort: 'Zip code must be 3-10 characters.',
    passwordAreNotSame: 'Passwords are not same.',
    passwordIsNotValid: 'Password is not valid.',
    change: 'Change',
    deliveryAndPaymentModal: 'Distance Sales Contract',
    haveToAccept: 'You must accept these agreements to complete the payment.',
    cardOwner: 'Card Owner',
    all: 'all',
    productSlug: 'product',
    allCategories: 'All Categories',
    noFavoriteProduct: 'There are no products in your favourites.',
    leftMenuTitle: 'MENU',
    leftMenuDresses: 'DRESSES',
    barcode: 'Barcode',
    guestLogin: 'GUEST LOGIN',
    continue: 'Continue',
    scrollTop: 'Scroll Top',
    anErrorOccurred: 'An error occurred',
    tryAgainLater: 'Try again later or continue to shopping.',
    runningOut: 'Running Out',
    noAddress: 'Registered address not found.',
    noOrder: 'Order not found.',
    noCoupon: 'You do not have an active discount coupon.',
    noSavedCard: 'No registered credit card found.',
    day: 'Day',
    hour: 'Hour',
    minute: 'Minute',
    second: 'Second',
    selectCountry: 'Select Country',
    selectState: 'Select State',
    selectProvince: 'Select Province',
    search: 'Search',
    shippingFee: '0 $',
    or: 'or',
    couponUse:
        'You can apply the coupons you want to use on the discount page.',
    contactMessage: 'Message',
    pleaseLoginForAddCoupon: 'Please login to add coupon',
    storageAndDestroy: 'Data Storage and Destroy',
    relatedPersonForm: 'RELATED PERSON APPLICATION FORM',
    customerClarifitacion: 'Customer and Visitor Clarification Text',
    zipCode: 'Zip Code',
    productAddedToCart: ' Product Added To Cart.',
    login: 'Login',
    mailAlreadyExist: 'This e-mail address is already registered.',
    tryAnotherMail: ' or try another e-mail address.',
    securePayment: 'Reliable Payment',
    checkCreditCardInfo: 'Check Credit Card Information',
    userAgreement: 'User Agreement',
    in: 'in',
    listingCategoryDescriptionCount: 'product have found',
    cartContinueShopping: 'Continue Shopping',
    checkoutCreateAddress: 'CREATE NEW ADDRESS',
    page: 'Page',
    keikei: 'KeiKei',
    canonicalText: '?page=',

    firstName: 'First Name',
    lastName: 'Last Name',
    close: 'Close',
    couponCodeError: 'Coupon code is incorrect.',
    campaignTitle: 'New Member Discount',
    models: 'Models',
    whenAddTwoProduct: 'When you add two products',
    ekstraDiscount: 'Ekstra Discount',
    onlineShopping: 'KeiKei - Online Shopping',
    openInApp: 'Open in KeiKei App',
    open: 'OPEN',

    products: 'Products',
    suggestions: 'Suggestions',
    authInfoAggregator:
        'Yalnızca Belirtmiş/Doldurmuş olduğum ülke, doğum tarihi, iletişim ve cinsiyet bilgilerimin Kişisel Verilerin İşlenmesi Hakkında Aydınlatma Metninde belirtilen amaçlar doğrultusunda işlenmesine rıza veriyorum.',
    preInforming: 'Prior Notice Agreement',
    sizeTable: 'Size Table',
    sizeTableSizes: 'SIZES',
    sizeTableChest: 'CHEST/cm',
    sizeTableWaist: 'WAIST/cm',
    sizeTableHip: 'HIP/cm',
    turnHomepage: 'TURN TO HOMEPAGE',
    catchTheOffers: 'Catch the special offers for you at keikei.com',
    completeOrderWithoutLogin: 'Complete your order without login.',
    addressTitleHome: 'Home, Work, etc.',
    fastDelivery: 'FAST DELIVERY',
    relatedProducts: 'Related Products',
    productTitleSeoText: 'PRICE AND INSTALLMENT OPTIONS',
    productDetails: 'Product Details',
    joinWaitlist: 'Join the waitlist',
    beNotified:
        'To be notified when a item becomes available, please enter your email below.',
    beNotifiedUser:
        ' Would you like to be notified when the product is released?',
    interestedIn: 'YOU MAY ALSO BE INTERESTED IN',
    listResult: 'list result',
    applyFilters: 'APPLY FILTERS',
    clearFilters: 'CLEAR FILTERS',
    chooseSize: 'Please choose a size',
    allSlug: 'all',
    stockOut: 'Stock Out',
    done: 'done',
    wantToNotify: 'Do you want to be notified when a item becomes available ?',
    discountBadgeText1: 'For every 1000 TL purchase ',
    discountBadgeText2: '100 TL gift',
    niceChooseText: 'Nice choose! This Dress will suit you very well✨ ',
    keikeiTitle: 'KeiKei |',
    returnConditions: 'Return Conditions',
    backToMyOrders: 'BACK TO MY ORDERS',
    selectProducts: 'SELECT PRODUCTS and CONTINUE',
    easyReturnRequest: 'Easy Return',
    continueShopping: 'CONTINUE SHOPPING',
    returnPackage: 'Return Package',
    changeProduct: 'Change Product',
    confirmReturn: 'Confirm Return Reguest',
    selectReturnedItem: 'Please select the products you want to return.',
    sendTheProduct:
        'Send the product with all its accessories in a shipping cart or a protected box in such a way that the original box will not be damaged.',
    packWithInvoice:
        'Only pack products with the same return code along with the invoice.',
    giveThePackage:
        'Give the package with your return code to Yurtiçi Cargo within 7 days at the latest.',
    sevenDays: 'within 7 days',
    applyForRefund: "if you don't use it, you have to apply for a refund.",
    makeProcess: 'to be processed',
    cargoInfo: 'cargo company information',
    reasonReturn: 'Reason for return',
    requestReceived: 'RETURN REQUEST RECEIVED',
    returnCode: 'Return Confirmation Code',
    cargoCompany: 'Cargo Company',
    followingProducts:
        'You have requested a refund for the following products. ',
    approve: 'Do you approve?',
    amountRefunded: 'Amount to Be Refunded',
    amountCoupon: 'Coupon Amount to Deposit',
    contractNo: 'Contract No',
    infoNotValid: 'Email or order number is not valid',
    orderNo: 'Order No',
    orderTracing: 'Order Tracing',
    orderTracingInfo: 'You can track your order by signing in to your account.',
    returnIn14Days: 'Return in 14 days',
    freeCargo: 'Free Cargo',
    installmentOption: 'Installment Options',
    originalProduct: ' 100% Original Product Guarantee',
    cargoTracking: 'Cargo Tracking',
    whereIsMyCargo: 'Cargo Tracking',
    viewGallery: 'View all gallery',
    shareStyle:
        'The combinations you create with your KeiKei branded clothes make our followers very happy. Show your style, share your outfit.',
    buy: 'Buy',
    choice: 'Choices',
    updateTheChoice: 'UPDATE CHOICES',
    language: 'Language',
    productAddedFail:
        "The product could not be added because it is out of stock or in someone else's cart. Please try again later.",
    wrongPlace: 'you came to the wrong place',
    single: 'Single',
    payment: 'Payment',
    tl: '$',
    returnInformation: 'Return Information',
    contactAgreement: 'Dialog Box Visitor Clarification Text',
    contactNotifyTitle: 'Your message has been sent!',
    contactNotifyDesc: 'Thank you for your attention.',
    paypalWarning:
        'To be able to pay with Paypal, you must confirm the contracts.',
    paymentFail: 'Payment failed.',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    newCollection: 'OUR NEW COLLECTION RELEASES IN...',
    goatSlug: '/goat',
    allProductSlug: '/all',
    dressSlug: '/dress',
    lastChance: 'Last Chance',
    hurryUp: 'Hurry up and get your orders in before it’s too late!',
    februaryDate: 'New Prices for February 1st.',
    priceIncreaseNotification: 'PRICES WILL INCREASE ON FEBRUARY 1ST',
    favoriteLoginAlert: 'Login for add to favourite.',
    specialsOffersForMember: 'Special Offers for KEIKEI Members',
    defectiveSale: 'Defective Sale',
    defectiveWarning:
        'We do not accept returns and exchanges for this product.',
    defectiveWarningListing:
        'The products in this category are defective, and they are being sold at an 80% discount. Returns and exchanges are not accepted for this category.',
    orderTracking: 'Order Tracking',
    followUs: 'Follow Us',
    downloadApp: 'Download Our App',
    freeShippingTurkiye: 'Free shipping all over Turkey',
    installmentPayment: 'Installment payment options',
    secureShopping: 'Secure shopping',
    popularSearches: 'Popular Searches',
    height: 'Height',
    bust: 'Bust',
    hips: 'Hips',
    size: 'Size',
    waist: 'Waist',
    modelMeasurements: 'Model Measurements',
    notSignupYet: 'Have you not become a member yet?',
    signupNowCampaign: 'Sign up now, special for your first purchase',
    getDiscount: 'take advantage of the discount!',
    shopNowMetaDesc:
        'Order now for free shipping and safe shopping opportunities!',
    inKeiKeiCo: 'in keikei.com',
    inKeiKei: 'in KeiKei!',
    clickKeiKeiCo: 'Click keikei.com now',
    mostStylish: 'the most stylish',
    for: 'for',
    accountMetaDesc:
        'Welcome to the KeiKei account page. We are here to manage your account, check your order history and order tracking.',
    aboutUsMetaDesc:
        "KeiKei is an e-commerce site that offers the latest and quality products at affordable prices for fashion enthusiasts in the women's clothing industry. Visit our about us page for more information.",
    contactMetaDesc:
        'As KeiKei customer service team, we are always in touch with you. Contact us, ask your questions and get help with anything.',
    privacyPolicyMetaDesc:
        'To learn more about KeiKei lighting text, visit our page.',
    cookiePolicyMetaDesc:
        'To learn more about the KeiKei cookie policy, visit our page.',
    membershipInfoMetaDesc:
        'Welcome to the KeiKei membership information page. Here are all the details you need to know about membership.',
    faqMetaDesc:
        'With the KeiKei frequently asked questions page, we answer the most frequently asked questions from our customers.',
    membershipAgreementMetaDesc:
        'To learn more about the KeiKei membership agreement, visit our page.',
    distanceSaleContractMetaDesc:
        'To learn more about the KeiKei distance selling agreement, visit our page.',
    distanceSalesPreInfoMetaTitle:
        'Distance Sales Preliminary Information Form',
    distanceSalesPreInfoMetaDesc:
        "Welcome to the KeiKei distance selling pre-information page. Here's what you need to know about distance selling.",
    storageAndDestroyMetaDesc:
        'To learn more about the KeiKei retention and disposal policy, visit our page.',
    relatedPersonFormMetaTitle: "Related Person's Request Form",
    relatedPersonFormMetaDesc:
        'Welcome to the KeiKei contact application form page. We are here to manage your personal data.',
    changeAndReturnMetaTitle: 'Change and Return',
    changeAndReturnMetaDesc:
        "Here's what you need to know about exchanges and returns on products you buy on KeiKei.",
    customCookiesMetaTitle: 'Custom Cookies',
    customCookiesMetaDesc:
        'To learn more about KeiKei custom cookies, visit our page.',
    seeAllProducts: 'See All Products',
    allCategoriesMetaDesc:
        "KeiKei has the latest fashions in women's clothing. Dresses, blouses, pants, skirts, overalls and more. Check out all our categories.",
    termsConditionsMetaDesc:
        'High-quality products and customer satisfaction go hand in hand at KeiKei. Check out our terms and conditions page for all the information you may need during your shopping process.',
    rightsAndAssignmentsMetaDesc:
        "Keikei's 'Rights and Duties' page is clearly stated in all your rights and duties. If you want to shop safely while protecting your rights, visit our page for details.",
    newUser: 'New User',
    newUserUpper: 'NEW USER',
    createAccount: 'Create Account',
    continueWithoutRegister: 'Continue Without Register',
    newRegister: 'New Register',
    registerNow: 'Register now to take advantage of membership opportunities.',
    freeShippingForTurkey: 'Free shipping all over Turkey',
    installmentPaymentOption: 'INSTALLMENT payment options',
    secureShop: 'SECURE shopping',
    newSeasonText: 'New Season Models and Deals',
    categorySEOTitle: 'Models and Deals',
    message: 'Message',
    complaintSuccess: 'Your complaint has been received.',
    complaintMessage: 'You can share your questions, comments, and suggestions about Keikei products and other matters using the form below.'
};
