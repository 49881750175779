import { useEffect, useRef, useState } from 'react';

const useIsScrolling = () => {
    const [isScrolling, setIsScrolling] = useState(false);
    const timeoutRef = useRef(null);
    const lastPositionRef = useRef(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentPosition = window.scrollY;
            setIsScrolling(currentPosition > lastPositionRef.current);

            if (timeoutRef.current !== null) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
                setIsScrolling(false);
            }, 100);

            lastPositionRef.current = currentPosition;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return isScrolling;
};

export default useIsScrolling;
