import styled from 'styled-components';
import Link from 'next/link';

const Logo = ({ width }) => (
    <LogoWrapper>
        <Link href="/" to="/" passHref>
            <StyledLogo
                src="https://cloud.keikei.com/cdn/banners/LOGO3.png"
                alt="keikei logo"
                width={width}
                height="38px"
            />
        </Link>
    </LogoWrapper>
);
const LogoWrapper = styled.div``;
const StyledLogo = styled.img`
    width: ${({ width }) => width || '100px'};
    cursor: pointer;

    @media (max-width: 1024px) {
        width: ${({ width }) => width || '120px'};
    }

    @media (max-width: 768px) {
        width: ${({ width }) => width || '110px'};
    }
`;

export default Logo;
