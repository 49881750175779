function TickIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 11 9"
            width="20px"
            height="15px"
            {...props}
        >
            <path
                d="M10.328.266c.419.352.476.983.123 1.415l-5.125 6.26a.988.988 0 01-1.396.14l-.036-.03a.972.972 0 01-.167-.18.98.98 0 01-.374-.21l-2.166-1.95a.995.995 0 01-.073-1.399l.032-.036a.997.997 0 011.4-.073l1.852 1.666L8.895.376a.988.988 0 011.397-.14l.036.03z"
                fill={props.fill ? props.fill : '#ffffff'}
                fillRule="evenodd"
            />
        </svg>
    );
}

export default TickIcon;
