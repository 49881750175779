function FavoriteIcon(props) {
    return (
        <svg
            width="20"
            height="20"
            {...props}
            viewBox="0 0 17 15"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.5 14.125 2.12 7.639a3.936 3.936 0 0 1 0-5.5 3.783 3.783 0 0 1 5.411 0l.968.986.969-.986a3.783 3.783 0 0 1 5.41 0 3.932 3.932 0 0 1 0 5.5L8.5 14.125z"
                stroke={`${props.fill || '#2E2E2E'}`}
                strokeWidth=".9"
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            />
        </svg>
    );
}
export default FavoriteIcon;
