import styled from 'styled-components';

import { useTranslation } from '@hooks';
import { SearchIcon } from '@components/icons';

const DesktopSearchInput = ({ searchQuery, setSearchQuery, search }) => {
    const { t } = useTranslation();

    return (
        <Container>
            <SearchDesktopInputContainer
                onSubmit={(e) => search(e, searchQuery, 'submit')}
            >
                <SearchDesktopInput
                    placeholder={t('searchDesktopPlaceholder')}
                    value={searchQuery}
                    onChange={(event) => setSearchQuery(event.target.value)}
                    data-testid="search-input"
                    autoFocus
                />

                <SearchButton>
                    <SearchIcon />
                </SearchButton>
                <input type="submit" hidden name="search" id="search" />
            </SearchDesktopInputContainer>
        </Container>
    );
};
const Container = styled.div`
    display: flex;
    align-items: flex-end;
    align-content: center;
    justify-content: space-around;
    position: relative;
    width: 100%;
    margin-bottom: 30px;

    @media (max-width: 1024px) {
        margin-top: 70px;
        margin-bottom: 20px;
    }
`;
const SearchDesktopInputContainer = styled.form`
    width: 100%;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary}`};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const SearchDesktopInput = styled.input`
    width: 100%;
    height: 32px;
    border: none;
    outline: none;
    border-radius: 0 10px 0 0;
    background: none;

    font-family: var(--futura-font);
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.sizes.xs};
    color: ${({ theme }) => theme.colors.primary};
    line-height: 16px;
    letter-spacing: 1px;

    ::placeholder {
        color: ${({ theme }) => theme.colors.primary};
        padding-top: 1px;
    }
`;

const SearchButton = styled.button`
    border: none;
    z-index: 999;
    display: block;
    background: none;
`;

export default DesktopSearchInput;
