import styled from 'styled-components';

import { Caption, Caption2 } from '@components/styles/Typography';

const Suggestion = ({ text, parentCategory, shouldBeBold, search, slug }) => {
    const textArray = text.split(shouldBeBold);

    return (
        <SuggestionWrapper
            onClick={(e) => search(e, slug)}
            data-testid="suggestion-item"
        >
            {textArray.map((item, index) => (
                <Caption key={item}>
                    {item}
                    {index !== textArray.length - 1 && <b>{shouldBeBold}</b>}
                </Caption>
            ))}
            <div>
                <Caption2 color="bgGray50">
                    <Caption color="bgGray50">{parentCategory}</Caption>{' '}
                    kategorisinde ara
                </Caption2>
            </div>
        </SuggestionWrapper>
    );
};

const SuggestionWrapper = styled.div`
    cursor: pointer;
    margin-bottom: 10px;

    span:first-child {
        text-transform: capitalize;
    }

    :hover {
        span {
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`;

export default Suggestion;
