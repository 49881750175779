import styled from 'styled-components';
import { CloseIcon } from 'components/icons';

const CloseButton = ({
    close,
    desktopWhite,
    flexStart,
    right,
    top,
    padding,
    testId,
}) => (
    <CloseButtonWrapper
        desktopWhite={desktopWhite}
        flexStart={flexStart}
        right={right}
        top={top}
        padding={padding}
        onClick={close}
        data-testid={testId}
    >
        <CloseIcon />
    </CloseButtonWrapper>
);

const CloseButtonWrapper = styled.button`
    display: flex;
    align-self: ${({ flexStart }) => (flexStart ? 'flex-start' : 'flex-end')};
    background: transparent;
    border: none;
    cursor: pointer;
    top: ${({ top }) => top || 0};
    left: ${({ right }) => (right ? '90%' : 0)};
    z-index: 9;
    position: absolute;
    width: 100%;
    padding: ${({ padding }) => padding || '20px'};

    @media (min-width: 1024px) {
        svg {
            fill: ${({ desktopWhite }) => desktopWhite && 'white'};
        }
    }

    @media (max-width: 1024px) {
        left: ${({ right }) => (right ? '92%' : 0)};
    }

    @media (max-width: 768px) {
        left: ${({ right }) => (right ? '85%' : 0)};
    }
`;

export default CloseButton;
