import styled from 'styled-components';
import { useSpring, animated } from '@react-spring/web';

import useTranslation from '@hooks/useTranslation';
import { Subtitle3 } from '@components/styles/Typography';
import MenuItem from '../Nav/MenuItem';

const ChildHeaderElem = ({ child, setActiveParent }) => {
    const [props] = useSpring(
        () => ({
            from: { opacity: 0 },
            to: { opacity: 1 },
            delay: 200,
        }),
        [],
    );

    const { locale } = useTranslation();
    return (
        <ItemWrapper>
            {child.data && (
                <animated.div style={props}>
                    <Wrapper>
                        <Subtitle3>{child?.group_name?.[locale]}</Subtitle3>

                        {child.data?.map((item) => (
                            <MenuItem
                                key={item.id}
                                color="bgGray50"
                                count={item?.productCount}
                                id={item?.id}
                                name={item?.name[locale]}
                                slug={`/${item?.slug?.[locale]}`}
                                sort={item?.sort}
                                parentId={item?.parent}
                                setActiveParent={setActiveParent}
                                isSub
                            />
                        ))}
                    </Wrapper>
                </animated.div>
            )}
        </ItemWrapper>
    );
};

const ItemWrapper = styled.div`
    display: flex;
    padding: 15px 0 30px 0;
`;

const Wrapper = styled.ul`
    display: flex;
    flex-direction: column;
    width: max-content;
    margin: 0;
    padding: 0;

    > * {
        &:first-child {
            margin-bottom: 12px;
        }
    }

    a {
        p {
            margin: 3px 0;
        }
    }
`;

export default ChildHeaderElem;
