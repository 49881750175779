function BagIcon(props) {
    return (
        <svg
            width="18"
            height="18"
            {...props}
            viewBox="0 0 14 16"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14 16V4.364h-2.7C10.752 1.837 9.094 0 7.133 0 5.17 0 3.513 1.837 2.966 4.364H0V16h14zM7.133.727c1.58 0 2.922 1.52 3.421 3.637H3.711c.5-2.117 1.84-3.637 3.422-3.637zM.922 5.235h1.923a6.557 6.557 0 0 0-.072.947.364.364 0 1 0 .727 0c0-.374.025-.595.073-.947h7.12c.047.352.073.573.073.947a.364.364 0 1 0 .726 0c0-.373-.027-.592-.071-.947h1.713v9.902H.922V5.235z"
                fill={`${props.fill || '#fff'}`}
                fillRule="evenodd"
                {...props}
            />
        </svg>
    );
}

export default BagIcon;
