import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@hooks';
import staticMenuItems from '@lib/menuItems';
import { SharedServices } from '@services';

import MenuItem from '../Nav/MenuItem';

const HeaderHorizontalMenu = ({
    setActiveItem,
    activeParent,
    setActiveParent,
}) => {
    const { locale } = useTranslation();

    const { data, isLoading } = useQuery({
        queryKey: ['menuItems'],
        queryFn: SharedServices.getMenuItems,
    });
    const menuItems = isLoading ? staticMenuItems : data?.data;

    const onHover = (items) => {
        setActiveItem(items);
    };

    return (
        <HeaderHorizontalMenuWrapper>
            {menuItems?.map((item) => (
                <MenuItem
                    hideProductCount
                    key={item?.id}
                    active={activeParent === item?.id}
                    count={item?.productCount}
                    name={item?.name[locale]}
                    slug={`${item?.slug[locale]}`}
                    sort={item?.sort}
                    setActiveParent={() => setActiveParent(null)}
                    onHover={() => onHover(item)}
                />
            ))}
        </HeaderHorizontalMenuWrapper>
    );
};

const HeaderHorizontalMenuWrapper = styled.div`
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;

    @media (max-width: 1280px) {
        gap: 15px;
    }
`;

export default HeaderHorizontalMenu;
