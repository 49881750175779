const FilledBagIcon = (props) => (
    <svg
        width={18}
        height={18}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0V0z" />
            <g fill={`${props.fill || '#2e2e2e'}`}>
                <path d="M21 22V7.455h-3.472C16.824 4.296 14.692 2 12.171 2 9.649 2 7.517 4.296 6.813 7.455H3V22h18zM7.771 7.455l-.177 1.089.014 1.698H6.36l.297-1.698.155-1.09h.958zm9.757 0 .156 1.089.355 1.698h-1.247l-.044-1.698-.178-1.09h.958zm-5.357-4.546c2.032 0 3.757 1.9 4.399 4.546H7.771c.643-2.647 2.367-4.546 4.4-4.546zM4.185 8.544h2.473a7.973 7.973 0 0 0-.092 1.183c0 .251.21.455.467.455a.461.461 0 0 0 .467-.455c0-.467.033-.743.094-1.183h9.154c.061.44.094.716.094 1.183 0 .251.21.455.467.455a.461.461 0 0 0 .467-.455c0-.466-.035-.74-.092-1.183h2.203V20.92H4.185V8.544z" />
                <path d="M4.185 8.544h2.473a7.973 7.973 0 0 0-.092 1.183c0 .251.21.455.467.455a.461.461 0 0 0 .467-.455c0-.467.033-.743.094-1.183h9.154c.061.44.094.716.094 1.183 0 .251.21.455.467.455a.461.461 0 0 0 .467-.455c0-.466-.035-.74-.092-1.183h2.203V20.92H4.185V8.544z" />
            </g>
        </g>
    </svg>
);

export default FilledBagIcon;
