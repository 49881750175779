import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { useCookies } from 'react-cookie';

import { useUserIP } from '@hooks';
import { NewButton, NewCloseButton, RightModal } from '@components/ui';
import { TitleWrapper } from '@components/common/Search';
import { Caption, Caption2 } from '@components/styles/Typography';

import defaultLocalesConfig from '@lib/localesConfig';
import { sendRegionToGA } from '@lib/gtm';
import { useModal } from '@context/ModalContext';

Modal.setAppElement('#__next');

const LangModal = () => {
    const [cookie, setCookie] = useCookies(['NEXT_LOCALE', 'LANG_ASKED']);
    const [ip] = useUserIP();
    const router = useRouter();
    const [showed, setShowed] = useState(!!cookie?.LANG_ASKED || false);
    const [selected, setSelected] = useState(router.locale);
    const [otherLocaleWarning, setOtherLocaleWarning] = useState(false);
    const {
        langModalIsOpen: isOpen,
        openLangModal,
        closeLangModal,
    } = useModal();

    const data = useQuery({
        queryKey: ['getCountry'],
        queryFn: () =>
            fetch(
                `https://api.iplocation.net/?ip=${ip}&format=json`,
            ).then((res) => res.json()),
        enabled: !!ip,
    });

    const getRequestCountryCode = () => {
        if (data.data?.country_code2 === 'GB') {
            return 'uk';
        }
        return data.data?.country_code2.toLowerCase();
    };

    const requestCountryCode = getRequestCountryCode();
    const requestCountryName = data.data?.country_name;
    const tempLang = requestCountryCode === 'tr' ? 'tr' : 'en';

    // if user close or select language dont ask again for this session
    const onModalShowedOnce = () => {
        setShowed(true);
        setCookie('LANG_ASKED', true);
    };

    const saveCookie = () => {
        setCookie('NEXT_LOCALE', selected, {
            maxAge: 10000 * 365,
        });
        if (router.pathname === '/[category]') {
            if (selected === 'tr') {
                router.push('/tum-urunler', null, { locale: selected });
            } else {
                router.push('/all', null, { locale: selected });
            }

            closeLangModal();
            onModalShowedOnce();
            return;
        }
        sendRegionToGA(selected);

        router.push(router.asPath, router.asPath, { locale: selected });
        closeLangModal();
        onModalShowedOnce();
    };

    const onClose = () => {
        closeLangModal();
        onModalShowedOnce();
    };

    useEffect(() => {
        // if (window?.location?.hostname === 'localhost') {
        //     setIsOpen(false);
        //     return;
        // }
        if (!requestCountryCode) return;
        if (requestCountryCode === router.locale) {
            setCookie('NEXT_LOCALE', requestCountryCode, {
                maxAge: 10000 * 365,
            });
        } else {
            if (!defaultLocalesConfig[requestCountryCode]) {
                setOtherLocaleWarning(true);
                openLangModal();
                return;
            }
            if (showed) return;
            // if locale and user country is not same ask again
            setSelected(requestCountryCode);
            openLangModal();
        }
    }, [requestCountryCode, router.locale]);

    return (
        <RightModal isOpen={isOpen} closeModal={onClose} height="100%">
            <TitleWrapper>
                <Caption2>
                    {tempLang === 'en' ? 'Choices' : 'Tercihler'}
                </Caption2>
                <NewCloseButton
                    close={onClose}
                    testId="lang-modal-close-button"
                />
            </TitleWrapper>

            <LangModalContainer data-testid="lang-modal">
                <Wrapper>
                    <SelectContainer>
                        {otherLocaleWarning && (
                            <Caption2>
                                Sorry, we are currently unable to ship to{' '}
                                {requestCountryName}. You can choose one of the
                                listed countries.
                            </Caption2>
                        )}
                        {requestCountryCode !== router.locale &&
                            !otherLocaleWarning &&
                            (tempLang === 'en' ? (
                                <Caption2>
                                    You are accessing the website for{' '}
                                    {defaultLocalesConfig[router.locale]?.label}
                                    , would you like to change to{' '}
                                    {
                                        defaultLocalesConfig[requestCountryCode]
                                            ?.label
                                    }{' '}
                                    ?
                                </Caption2>
                            ) : (
                                <Caption2>
                                    {defaultLocalesConfig[router.locale]?.label}{' '}
                                    sitesine erişiyorsunuz,
                                    {
                                        defaultLocalesConfig[requestCountryCode]
                                            ?.label
                                    }{' '}
                                    sitesine erişmek ister misiniz?
                                </Caption2>
                            ))}
                        <SelectTitle>
                            {tempLang === 'en' ? 'Country' : 'Ülke'}
                        </SelectTitle>
                        <Select
                            onChange={(e) => setSelected(e.target.value)}
                            data-testid="lang-select"
                        >
                            {Object.keys(defaultLocalesConfig).map((locale) => (
                                <option
                                    key={locale}
                                    value={locale}
                                    selected={locale === selected}
                                >
                                    {defaultLocalesConfig[locale].label}
                                </option>
                            ))}
                        </Select>
                    </SelectContainer>
                    <NewButton
                        type="primary"
                        size="xlarge"
                        content={
                            tempLang === 'en'
                                ? 'UPDATE CHOICES'
                                : 'TERCİHLERİ GÜNCELLE'
                        }
                        data-test-id="accept-lang-cookie"
                        handleClick={saveCookie}
                    />
                </Wrapper>
            </LangModalContainer>
        </RightModal>
    );
};

const LangModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100vh;
    padding: 40px 0;

    option {
        -webkit-appearance: none;
        color: black;
        -moz-appearance: none;
        appearance: none;
        -webkit-border-radius: 0;
        border-radius: 0;
    }

    @media (max-width: 768px) {
        left: unset;
        top: unset;
        justify-content: space-between;
        padding: 0 0 150px 0;
        height: 80%;
    }
`;
const Wrapper = styled.div`
    width: 345px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @media (max-width: 768px) {
        justify-content: space-between;
    }
`;
const SelectContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;

    option {
        -webkit-appearance: none;
        color: black;
        -moz-appearance: none;
        appearance: none;
    }

    @media (max-width: 768px) {
        margin-top: 60px;
    }
`;
const SelectTitle = styled(Caption)`
    margin: 20px 0 2px 2px;
`;

const Select = styled.select`
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid black;
    width: 100%;
    height: 30px;
    color: ${({ theme }) => theme.colors.primary};
    -webkit-border-radius: 0;
    border-radius: 0;

    @media (max-width: 768px) {
        height: 50px;
        background-color: ${({ theme }) => theme.colors.secondary};
        opacity: 80%;
        padding: 5px 10px;
    }
`;

export default LangModal;
