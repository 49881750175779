/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import Image from 'next/image';

import { useTranslation } from '@hooks';
import { truncateString } from '@utils';
import { SliderPrice, SliderVariant } from '@components/ui';
import { Caption2 } from '@components/styles/Typography';

const SliderProductCard = ({ product }) => {
    const {
        images,
        image,
        name,
        price,
        discount_rate: discountRate,
        discounted_price: discountedPrice,
    } = product;
    // I've set this controller like this because the image data in the 'Trend of the Week Slider' contains "images" object of different sizes(thumb,medium,small etc.), while the search modal only returns a single image named as "image".
    // If we don't set this up like this; in prod we will see "Opss!" error because the "image" is a single object and  dont contain any child objects so we can't meausere lenght of it.
    const productImageLength = product?.images
        ? Object.keys(product?.images?.urls).length
        : 1;
    const { locale, useCurrency, t } = useTranslation();
    const [currentImage, setCurrentImage] = useState(
        product?.images && productImageLength
            ? product?.images?.urls?.medium
            : product?.image,
    );
    const [currentSlug, setCurrentSlug] = useState(product?.slug);
    const [selectedVariant, setSelectedVariant] = useState(null);

    const changeProduct = (id) => {
        const newProduct = product?.variants.find(
            (productItem) => productItem?.id === id,
        );
        setCurrentImage(newProduct?.images[0]?.urls?.medium);

        setSelectedVariant(id);

        if (newProduct?.slug?.[locale]) {
            setCurrentSlug(newProduct.slug[locale]);
        }
    };
    useEffect(() => {
        setCurrentImage(
            product?.images && productImageLength
                ? product?.images?.urls?.medium
                : product?.image,
        );
        setCurrentSlug(product?.slug[locale]);
        setSelectedVariant(product?.id);
    }, [product]);
    const imageLink =
        productImageLength > 0 ? product?.images?.urls?.medium : product?.image;
    return images || image ? (
        <SliderContent className={`keen-slider__slide slide${product.id}`}>
            <Link
                href={`/${t('productSlug')}/${currentSlug}`}
                to={`/${t('productSlug')}/${currentSlug}`}
                passHref
            >
                <HomepageProductCard>
                    <NextImageWrapper>
                        <Image
                            src={currentImage || imageLink}
                            alt={name[locale]}
                            fill
                        />
                    </NextImageWrapper>

                    <SliderInfoWrapper>
                        <SliderPrice
                            discountRate={discountRate}
                            discountedPrice={useCurrency(discountedPrice)}
                            price={useCurrency(price)}
                            letterSpacing="3px"
                            direction="row"
                        />
                        <Title>
                            <Caption2
                                style={{
                                    textTransform: 'capitalize',
                                }}
                            >
                                {truncateString(name[locale], 30)}
                            </Caption2>
                            <Caption2
                                style={{
                                    textTransform: 'capitalize',
                                }}
                            >
                                {truncateString(name[locale], 25)}
                            </Caption2>
                        </Title>
                    </SliderInfoWrapper>
                </HomepageProductCard>
            </Link>
            <SliderVariant
                variantColors={product.variants}
                selectedVariant={selectedVariant}
                onProductChange={changeProduct}
                slug={currentSlug}
            />
        </SliderContent>
    ) : null;
};
const SliderContent = styled.div`
    position: relative;
    padding: 20px 0;
`;
const HomepageProductCard = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    img {
        height: 291px;
        transition: all 0.3s ease-in-out;
    }
    @media (max-width: 1024px) {
        align-items: unset;
    }
    @media (max-width: 768px) {
        padding: 0;
        img {
            height: 180px;
        }
    }
`;

const NextImageWrapper = styled.div`
    width: 194px;
    height: 291px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease-in-out;
    img {
        object-fit: cover;
    }
    :hover {
        transition: all 0.3s ease-in-out;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
    @media (max-width: 768px) {
        width: 165px;
    }
`;
const SliderInfoWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 6px;
    margin-top: 10px;
    p {
        text-transform: capitalize;
        margin-bottom: 5px;
    }
`;
const Title = styled.div`
    padding: 8px 0;
    text-transform: capitalize;
    span {
        :last-child {
            display: none;
        }
    }
    @media (max-width: 1024px) {
        span {
            :last-child {
                display: block;
            }
            :first-child {
                display: none;
            }
        }
    }
`;

export default SliderProductCard;
