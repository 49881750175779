import 'keen-slider/keen-slider.min.css';
import '../styles/global.css';
import '../styles/modal.css';

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Script from 'next/script';
import Head from 'next/head';
import localFont from 'next/font/local';
import { hotjar } from 'react-hotjar';
import { ModalProvider } from 'context/ModalContext';
import { AuthProvider } from 'context/AuthContext';
import { AccountProvider } from 'context/AccountContext';
import { CartProvider } from '@context/CartContext';
import { NotificationProvider } from '@context/NotificationContext';

import {
    Hydrate,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';

import { Layout } from '@components/common';
import { ThemeProvider } from 'styled-components';
import theme from 'styles/themes';
import { pageview, sendRegionToGA } from '@lib/gtm';
import { useEffectOnce } from 'react-use';
import { useCookies } from 'react-cookie';
import * as gtag from '../lib/gtag';

const futura = localFont({
    src: [
        {
            path: '../public/fonts/futura/FuturaNowHeadline.woff2',
            weight: 'normal',
            style: 'normal',
        },
        {
            path: '../public/fonts/futura/FuturaNowHeadlineLight.woff2',
            weight: '300',
            style: 'normal',
        },
        {
            path: '../public/fonts/futura/FuturaNowHeadlineMedium.woff2',
            weight: '500',
            style: 'normal',
        },
        {
            path: '../public/fonts/futura/FuturaNowHeadlineBold.woff2',
            weight: 'bold',
            style: 'normal',
        },
    ],
});

const MyApp = ({ Component, pageProps }) => {
    const [queryClient] = useState(() => new QueryClient());
    const router = useRouter();
    const { locale } = router;
    const [cookie] = useCookies(['user_cookie']);

    const [canLoadGTM, setCanLoadGTM] = useState(
        cookie?.user_cookie === 'accept_all',
    );

    useEffect(() => {
        if (cookie?.user_cookie === 'accept_all') {
            setCanLoadGTM(true);
            console.log(canLoadGTM);
            hotjar.initialize(2334524, 6);
        }
    }, [cookie]);

    useEffect(() => {
        const handleRouteChange = (url) => {
            pageview(url);
        };
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    useEffect(() => {
        const handleRouteChange = () => {
            sendRegionToGA(locale);
        };
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events, router.locale]);

    useEffectOnce(() => {
        sendRegionToGA(locale);
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <style jsx global>{`
                :root {
                    --futura-font: ${futura.style.fontFamily};
                }
            `}</style>
            <Head>
                <meta
                    name="viewport"
                    content="initial-scale=1.0, width=device-width maximum-scale=1.0"
                />
                <meta
                    name="p:domain_verify"
                    content="4b76e70eb78d87a27981297877a96c9a"
                />
                <meta
                    name="facebook-domain-verification"
                    content="zu3mw6z8odoq7jb2y6cixqst0oas59"
                />
                <meta name="theme-color" content="#fff" />
                <link
                    rel="alternate"
                    hrefLang="x-default"
                    href="http://www.keikei.com/"
                />
                {router.locale === 'uk' && (
                    <link
                        rel="alternate"
                        hrefLang="en"
                        href={`http://www.keikei.com${router.asPath}`}
                    />
                )}
                {router.locale === 'tr' && (
                    <link
                        rel="alternate"
                        hrefLang="tr"
                        href={`http://www.keikei.com/tr${router.asPath}`}
                    />
                )}
                {router?.locale !== 'tr' && router.locale !== 'uk' && (
                    <link
                        rel="alternate"
                        hrefLang={`en-${router.locale}`}
                        href={`http://www.keikei.com/${router.locale}${router.asPath}`}
                    />
                )}
                <link
                    rel="preconnect"
                    href="https://www.google-analytics.com/"
                />
                <link rel="preconnect" href="https://www.google.com.tr/" />
                <link
                    rel="preconnect"
                    href="https://www.googleadservices.com/"
                />
                <link
                    rel="preconnect"
                    href="https://googleads.g.doubleclick.net"
                />
            </Head>

            <Script
                strategy="worker"
                src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
            />

            <Script
                id="gtm-init"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-K8FXXCW');
          `,
                }}
            />

            <Script
                id="gtag-init"
                strategy="afterInteractive"
                // strategy="lazyOnload"
                dangerouslySetInnerHTML={{
                    __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}');
            gtag('config', 'AW-375195924');
          `,
                }}
            />

            {/* <Script
                id="tiktok-init"
                // strategy="lazyOnload"
                dangerouslySetInnerHTML={{
                    __html: `
                        !function (w, d, t) {
                w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
                var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
                ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
                ttq.load('CL70ITRC77U9OLIO7HKG');
                ttq.page();
                }(window, document, 'ttq');
          `,
                }}
            /> */}

            <QueryClientProvider client={queryClient}>
                <Hydrate state={pageProps.dehydratedState}>
                    <AuthProvider>
                        <AccountProvider>
                            <NotificationProvider>
                                <CartProvider>
                                    <ModalProvider>
                                        <Layout pageProps={pageProps}>
                                            <Component {...pageProps} />
                                        </Layout>
                                    </ModalProvider>
                                </CartProvider>
                            </NotificationProvider>
                        </AccountProvider>
                    </AuthProvider>
                </Hydrate>
            </QueryClientProvider>
        </ThemeProvider>
    );
};

export default MyApp;
