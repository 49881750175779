import styled from 'styled-components';
import router from 'next/router';

import { useTranslation } from '@hooks';
import { AccountMenu } from '@components/account';
import { NewButton, RightModal } from '@components/ui';
import { useModal } from '@context/ModalContext';

const AccountModal = ({ currentUser, isOpen, closeModal }) => {
    const { closeRightModal } = useModal();
    const { t } = useTranslation();
    const goLoginPage = () => {
        router.push({
            pathname: '/login',
            query: { cb: router.asPath, tab: 'login' },
        });
        closeRightModal();
    };
    const goSignupPage = () => {
        router.push({
            pathname: '/login',
            query: { cb: router.asPath, tab: 'register' },
        });
        closeRightModal();
    };
    return (
        <RightModal isOpen={isOpen} closeModal={closeModal}>
            {currentUser ? (
                <AccountMenu
                    modalPadding
                    withIcon
                    closeAccountModal={closeRightModal}
                    rightModalIsOpen={isOpen}
                />
            ) : (
                <ButtonWrapper>
                    <NewButton
                        size="xlarge"
                        type="primary"
                        content={t('userSignIn')}
                        handleClick={goLoginPage}
                        uppercase
                    />
                    <NewButton
                        size="xlarge"
                        type="secondary"
                        content={t('userSignUp')}
                        handleClick={goSignupPage}
                    />
                </ButtonWrapper>
            )}
        </RightModal>
    );
};

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    width: 100%;
    height: 100%;

    button {
        margin-bottom: 20px;
    }

    @media (max-width: 1024px) {
        padding: 20px;
    }
`;
export default AccountModal;
