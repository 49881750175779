import React from 'react';

function NotificationWarningIcon(props) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433284 8.00042 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9949 7.34939 18.9397 4.80881 17.0655 2.93455C15.1912 1.06028 12.6506 0.00508267 10 0ZM9.23077 5.38461C9.23077 5.1806 9.31182 4.98494 9.45607 4.84069C9.60033 4.69643 9.79599 4.61538 10 4.61538C10.204 4.61538 10.3997 4.69643 10.5439 4.84069C10.6882 4.98494 10.7692 5.1806 10.7692 5.38461V10.7692C10.7692 10.9732 10.6882 11.1689 10.5439 11.3132C10.3997 11.4574 10.204 11.5385 10 11.5385C9.79599 11.5385 9.60033 11.4574 9.45607 11.3132C9.31182 11.1689 9.23077 10.9732 9.23077 10.7692V5.38461ZM10 15.3846C9.77179 15.3846 9.54871 15.3169 9.35896 15.1902C9.16921 15.0634 9.02132 14.8832 8.93399 14.6723C8.84666 14.4615 8.82381 14.2295 8.86833 14.0057C8.91285 13.7818 9.02274 13.5762 9.18411 13.4149C9.34548 13.2535 9.55107 13.1436 9.7749 13.0991C9.99872 13.0546 10.2307 13.0774 10.4416 13.1648C10.6524 13.2521 10.8326 13.4 10.9594 13.5897C11.0862 13.7795 11.1538 14.0026 11.1538 14.2308C11.1538 14.5368 11.0323 14.8303 10.8159 15.0467C10.5995 15.263 10.306 15.3846 10 15.3846Z"
                fill="black"
            />
        </svg>
    );
}

export default NotificationWarningIcon;
