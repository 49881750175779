import { useQuery } from '@tanstack/react-query';

const useUserIP = () => {
    const { data } = useQuery({
        queryKey: ['getUsersIP'],
        queryFn: async () => {
            const res = await fetch('/api/getIP');
            const response = await res.json();
            return response;
        },
    });
    const ip = data?.ip.includes(',') ? data?.ip.split(',')[0] : data?.ip;
    return [ip];
};

export default useUserIP;
