import styled from 'styled-components';

export const Main = styled.main`
    min-height: calc(100vh - 260px);
    input,
    select,
    textarea {
        outline: none;
    }
    @media (max-width: 1024px) {
        background: ${({ mainBackgroundGray }) =>
            mainBackgroundGray && '#f5f5f7'};
    }
`;

export const StyledRow = styled.div`
    display: flex;
    justify-content: ${({ justifyContent }) =>
        justifyContent || 'space-between'};
    align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
    width: ${({ width }) => width || '100%'};
    background-color: ${({ background }) => background || 'transparent'};
    flex-wrap: ${({ flexWrap }) => flexWrap || 'wrap'};
    max-width: ${({ maxWidth }) => maxWidth || 'unset'};
    min-width: ${({ minWidth }) => minWidth || 'unset'};
    margin: ${({ margin }) => margin};
    gap: ${({ gap }) => gap};
`;

export const StyledColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: ${({ alignStart }) => (alignStart ? 'start' : 'center')};
    height: ${({ height }) => height};
    width: ${({ width }) => width || '100%'};
    max-width: ${({ maxWidth }) => maxWidth || 'unset'};
    align-self: ${({ alignSelf }) => alignSelf};
`;

export const HideMobile = styled.div`
    @media (max-width: 768px) {
        display: none;
    }
`;
export const HideTablet = styled.div`
    width: ${({ width }) => width || 'unset'};
    max-width: ${({ maxWidth }) => maxWidth || 'unset'};

    @media (max-width: 1024px) {
        display: none;
    }
`;
export const ShowTablet = styled.div`
    width: ${({ width }) => width || 'unset'};

    @media (min-width: 1025px) {
        display: none;
    }
`;
export const HideDesktop = styled.div`
    @media (min-width: 768px) {
        display: none;
    }
`;
export const Hr = styled.hr`
    width: 80px;
    height: 2px;
    background-color: #000000;
`;

export const IconCircle = styled.div`
    width: 34px;
    height: 34px;
    object-fit: contain;
    border: ${({ theme }) => `solid 0.8px ${theme.colors.tertiaryVariant}`};
    border-radius: 100%;
    background-color: ${({ theme }) => theme.colors.tertiaryVariant};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    :hover {
        background-color: ${({ theme }) => theme.colors.bgGray10};
        border: ${({ theme }) => `solid 0.8px ${theme.colors.bgGray10}`};
    }
`;

export const Divider = styled.div`
    width: 1px;
    height: 22px;
    background-color: ${({ theme }) => theme.colors.bgGray10};
    margin: ${({ margin }) => margin || '0 5px'};
`;
