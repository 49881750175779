import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import AccountServices from '@services/AccountServices';
import { useAuth } from './AuthContext';

const AccountContext = React.createContext();

export function useAccount() {
    return useContext(AccountContext);
}

export const AccountProvider = ({ children }) => {
    const queryClient = useQueryClient();
    const { currentUser } = useAuth();
    const [favoritesModelIDs, setFavoritesModelIDs] = useState([]);

    const userData = useQuery({
        queryKey: ['getUser'],
        queryFn: () => AccountServices.getUserData(currentUser),
        enabled: !!currentUser,
    });

    const addressesQuery = useQuery({
        queryKey: ['getAddresses'],
        queryFn: () => AccountServices.getAddresses(currentUser),
        enabled: !!currentUser,
    });

    const getFavoriteModelIDs = useQuery({
        queryKey: ['getFavoriteModelIDs'],
        queryFn: () => AccountServices.getFavoritesModelIDs(currentUser),
        enabled: !!currentUser,
        onSuccess: (data) => {
            setFavoritesModelIDs(data?.data?.favIds || []);
        },
    });

    const addToFavoritesMutation = useMutation({
        mutationFn: AccountServices.addToFavorites,
        onMutate: (variables) => {
            setFavoritesModelIDs(() => [
                ...favoritesModelIDs,
                variables.modelID,
            ]);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['getFavoriteModelIDs'],
            });
        },
    });

    const deleteFavoriteMutation = useMutation({
        mutationFn: AccountServices.deleteFromFavorites,
        onMutate: (variables) => {
            setFavoritesModelIDs(
                favoritesModelIDs.filter((item) => item !== variables.modelID),
            );
        },
        onSuccess: () => {
            // setFavoritesModelIDs(
            //     favoritesModelIDs.filter((item) => item !== data.model_id),
            // );
            queryClient.invalidateQueries({ queryKey: ['getFavorites'] });
        },
    });

    const userID = userData?.data?.data?.me?.id;

    const isFavorite = useCallback(
        (modelID) => favoritesModelIDs?.includes(modelID),
        [favoritesModelIDs],
    );

    const getAddressByID = useCallback(
        (addressID) =>
            addressesQuery?.data?.data.find(
                (address) => address.id === addressID,
            ),
        [addressesQuery?.data],
    );

    const filteredAddresses = useCallback(
        (countryID) =>
            addressesQuery?.data?.data?.filter(
                (address) => address?.country?.id === countryID,
            ),
        [addressesQuery?.data],
    );

    const value = useMemo(
        () => ({
            userData,
            addressesQuery,
            currentUser,
            userID,
            deleteFavoriteMutation,
            addToFavoritesMutation,
            getFavoriteModelIDs,
            isFavorite,
            getAddressByID,
            filteredAddresses,
        }),
        [
            userData,
            addressesQuery,
            currentUser,
            userID,
            deleteFavoriteMutation,
            addToFavoritesMutation,
            getFavoriteModelIDs,
            isFavorite,
            getAddressByID,
            filteredAddresses,
        ],
    );

    return (
        <AccountContext.Provider value={value}>
            {children}
        </AccountContext.Provider>
    );
};
