import React, { useContext, useMemo, useState } from 'react';
import { Notification } from '@components/ui';

const NotificationContext = React.createContext();

export function useNotification() {
    return useContext(NotificationContext);
}

export const NotificationProvider = ({ children }) => {
    const [show, setShow] = useState(false);
    const [content, setContent] = useState({
        type: 'warning',
        title: '',
        description: '',
        alert: false,
        time: 8000,
    });
    const close = () => {
        setShow(false);
    };
    const open = (content) => {
        setShow(true);
        setContent({
            type: content.type,
            title: content.title,
            description: content.description,
            alert: content.alert,
        });
    };

    const value = useMemo(
        () => ({
            open,
            close,
            show,
            setShow,
        }),
        [open, close, show, setShow],
    );

    return (
        <NotificationContext.Provider value={value}>
            {children}
            <Notification
                type={content.type}
                title={content.title}
                description={content.description}
                isOpen={show}
                setIsOpen={setShow}
            />
        </NotificationContext.Provider>
    );
};
