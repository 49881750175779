import styled from 'styled-components';

import { Caption, Caption2 } from '@components/styles/Typography';

const Price = ({ discountRate, discountedPrice, price }) => (
    <PriceContainer>
        {discountRate > 0 ? (
            <Wrapper>
                <Caption>{discountedPrice}</Caption>
                <DiscountRate>
                    <Caption color="secondary">%{discountRate}</Caption>
                </DiscountRate>
                <OldPrice>
                    <Caption2 color="bgGray25">{price}</Caption2>
                </OldPrice>
            </Wrapper>
        ) : (
            <Caption>{discountedPrice}</Caption>
        )}
    </PriceContainer>
);
const PriceContainer = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    gap: 10px;
`;
const OldPrice = styled.span`
    margin-left: 5px;

    span {
        text-decoration: line-through;
    }
`;
const Wrapper = styled.div`
    display: flex;
    align-items: baseline;
`;
const DiscountRate = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.primary};
    padding: 0 5px;
    margin-left: 5px;

    @media (max-width: 1024px) {
        margin-left: 1px;
    }
`;

export default Price;
