function SearchIcon(props) {
    return (
        <svg
            width={18}
            height={18}
            viewBox="0 0 15 15"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M5.617 11.264c1.351 0 2.628-.48 3.644-1.35l4.938 4.95a.467.467 0 0 0 .663.001.469.469 0 0 0 .001-.663L9.92 9.248c1.85-2.21 1.743-5.52-.33-7.598A5.575 5.575 0 0 0 5.616 0a5.575 5.575 0 0 0-3.974 1.65 5.648 5.648 0 0 0 0 7.964 5.579 5.579 0 0 0 3.975 1.65zm-3.31-8.952A4.644 4.644 0 0 1 5.617.938c1.25 0 2.425.488 3.31 1.374a4.709 4.709 0 0 1 0 6.64 4.644 4.644 0 0 1-3.31 1.375 4.644 4.644 0 0 1-3.31-1.375 4.71 4.71 0 0 1 0-6.64z"
                fill={`${props.fill ? props.fill : '#2E2E2E'}`}
                fillRule="nonzero"
                {...props}
            />
        </svg>
    );
}

export default SearchIcon;
