import { useModal } from 'context/ModalContext';
import { MenuIcon } from 'components/icons';

const MenuButton = ({ color }) => {
    const { openLeftMenuModal } = useModal();
    return (
        <div>
            <MenuIcon
                onClick={openLeftMenuModal}
                data-testid="menu-button"
                fill={color || '#fff'}
                width="14px"
            />
        </div>
    );
};

export default MenuButton;
