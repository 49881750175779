function NotificationErrorIcon(props) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433284 8.00042 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9949 7.34939 18.9397 4.80881 17.0655 2.93455C15.1912 1.06028 12.6506 0.00508267 10 0ZM13.625 12.5288C13.7694 12.6747 13.8505 12.8717 13.8505 13.0769C13.8505 13.2822 13.7694 13.4792 13.625 13.625C13.478 13.7672 13.2814 13.8466 13.0769 13.8466C12.8724 13.8466 12.6759 13.7672 12.5288 13.625L10 11.0865L7.47116 13.625C7.32413 13.7672 7.1276 13.8466 6.92308 13.8466C6.71856 13.8466 6.52203 13.7672 6.375 13.625C6.23056 13.4792 6.14952 13.2822 6.14952 13.0769C6.14952 12.8717 6.23056 12.6747 6.375 12.5288L8.91346 10L6.375 7.47115C6.25234 7.32169 6.18965 7.13195 6.19914 6.93884C6.20862 6.74572 6.2896 6.56303 6.42632 6.42631C6.56304 6.2896 6.74572 6.20862 6.93884 6.19913C7.13196 6.18965 7.3217 6.25234 7.47116 6.375L10 8.91346L12.5288 6.375C12.6783 6.25234 12.868 6.18965 13.0612 6.19913C13.2543 6.20862 13.437 6.2896 13.5737 6.42631C13.7104 6.56303 13.7914 6.74572 13.8009 6.93884C13.8104 7.13195 13.7477 7.32169 13.625 7.47115L11.0865 10L13.625 12.5288Z"
                fill="black"
            />
        </svg>
    );
}

export default NotificationErrorIcon;
